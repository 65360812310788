import React from "react";

function TechAppCard({ img, heading, pera }) {
  return (
    <div className="TechAppCard">
      <img src={img} alt="card" width={100} height={100}/>
      <h1>{heading}</h1>
      <p>{pera}</p>
    </div>
  );
}

export default TechAppCard;
