import React from "react";

function BlockchainText({ icon, title, para }) {
  return (
    <div className="template_div">
      <img src={icon} alt="paper" width={80}/>
      <h1>{title}</h1>
      <p>{para}</p>
    </div>
  );
}

export default BlockchainText;
