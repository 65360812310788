import { Button, Col, Row } from "antd";
import React from "react";
import waveWhite from "../../assets/wave-separator-white.png";
import gaming from "../../assets/gaming.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function WeBuild() {
  const history = useHistory();
  return (
    <>
      <div
        style={{
          // backgroundColor: "#f7f7f7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text_container">
          <Row align="middle">
            <Col xl={12} lg={12} md={24} sm={24} xs={24} className="mobileApp">
              <h1 style={{ position: "relative" }}>
                {/* We Build <span className="textStyle">Mobile</span> App */}
                "Empowering Your Vision with Cutting-Edge Technology and
                Exceptional Service"
              </h1>
              <p>
                At Decotechs, our mission is to deliver exceptional value to our
                clients through our expertise in web and mobile application
                development. Our team is dedicated to creating amazing user
                experiences and fostering open communication to ensure that each
                project is completed to the highest standards. We believe in
                delivering not just a product, but a complete experience that
                exceeds our clients' expectations.
              </p>
              <div className="btn_div">
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "var(--primary)",
                    fontFamily: "Muli",
                    fontSize: "12px",
                  }}
                  size="large"
                  shape="round"
                  onClick={() => history.push("/services")}
                >
                  LEARN MORE
                </Button>
                <Button
                  style={{
                    border: "2px solid var(--primary)",
                    color: "var(--primary)",
                    backgroundColor: "white",
                    fontFamily: "Muli",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  onClick={() => history.push("/contactus")}
                  size="large"
                  shape="round"
                >
                  CONTACT US
                </Button>
              </div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <img src={gaming} alt="image" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default WeBuild;
