import image from "./CryptoMories.jpg";

export const BusinessDeveloper = [
  {
    name: "Muhammad Akif",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Yasir Ahmed",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Sameer Arif",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Muhammad Saaid",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Ammad Inayat	",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Farhan Ahmed",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Hammad Khan",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Sufiyan Hussain",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Umar Ahmed",
    designation: "Business Developer",
    image: image,
  },
  {
    name: "Muhammad Sameer",
    designation: "Business Developer",
    image: image,
  },
];
export const developer = [
  {
    name: "Khizram Saeed",
    designation: "Mern Stack Developer	",
    image: image,
  },
  {
    name: "Afaque Ahmed",
    designation: "Mern Stack Developer",
    image: image,
  },
  {
    name: "Muhammad Ali",
    designation: "Mern Stack Developer",
    image: image,
  },
  {
    name: "Muhammad Kashan",
    designation: "Mern Stack Developer",
    image: image,
  },
  {
    name: "Ahmed Hasan",
    designation: "Mern Stack Developer",
    image: image,
  },
  {
    name: "Usama Waseem",
    designation: "React Native Developer",
    image: image,
  },
  {
    name: "Usama Ansari",
    designation: "Frontend Developer",
    image: image,
  },
  {
    name: "Muhammad Umer",
    designation: "Frontend Developer",
    image: image,
  },
  {
    name: "Mubashir",
    designation: "Frontend Developer",
    image: image,
  },
  {
    name: "Yaruq Khan",
    designation: "Wordpress Developer",
    image: image,
  },
  {
    name: "Hashmin Shaukat",
    designation: "Wordpress shopify Developer",
    image: image,
  },
  {
    name: "Zain",
    designation: "ASP .NET Developer",
    image: image,
  },
  {
    name: "Afnan",
    designation: "ASP .NET Developer",
    image: image,
  },
  {
    name: "Anas",
    designation: "ASP .NET Developer",
    image: image,
  },
  {
    name: "Zubair",
    designation: "ASP .NET Developer",
    image: image,
  },
];

export const Graphic = [
  {
    name: "Obaid Afzal Qureshi",
    designation: "Graphic Designer",
    image: image,
  },
  {
    name: "Asad Nadeem",
    designation: "Graphic Designer",
    image: image,
  },
  {
    name: "Asad Ullah Khan",
    designation: "UI/UI Developer",
    image: image,
  },
];

export const BlockChainDeveloper = [
  {
    name: "Muhammad Shehroz",
    designation: "Blockchain Developer",
    image: image,
  },
  {
    name: "Komail Naqvi",
    designation: "Blockchain Developer",
    image: image,
  },
  {
    name: "Avinash Kumar",
    designation: "Blockchain Developer",
    image: image,
  },
];

export const HumanResource = [
  {
    name: "Usama Hashmi",
    designation: "Human Resource",
    image: image,
  },
  {
    name: "Hina Saleem",
    designation: "Human Resource",
    image: image,
  },
];
