import React from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { Switch } from "antd";

import { MdOutlineDarkMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { toggleTheme } from "../redux";
import HeaderDrawer from "../components/Drawer/HeaderDrawer";
import DropDown from "../components/Drawer/DropDown";
import blueLogo from "../assets/blueLogo.png";
import ServicesDropDown from "../components/Drawer/ServicesDropDown";

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const Blockchain = location.pathname === "/blockchain";

  return (
    <div
      className="header-container"
      style={{
        backgroundColor: Blockchain ? "var(--textColor2)" : null, position: "fixed", width: "100%",
      }}
    >
      <div className="left">
        <img
          src={blueLogo}
          alt="LOGO"
          width={100}
          onClick={() => history.push("/")}
        />
        <h2
          onClick={() => history.push("/")}
          style={{ color: Blockchain ? "var(--textColor)" : null }}
        >
          <span className={location.pathname === "/" ? "textStyle" : undefined}>
            HOME
          </span>
        </h2>
        <ServicesDropDown />
        <h2
          onClick={() => history.push("/our-portfolio")}
          style={{ color: Blockchain ? "var(--textColor)" : null }}
        >
          <span
            className={
              location.pathname === "/our-portfolio" ? "textStyle" : undefined
            }
          >
            PORTFOLIO
          </span>
        </h2>
        <DropDown style={{ color: Blockchain ? "var(--textColor2)" : null }} name="CLIENTS" items={[
          // {
          //   key: "3",
          //   label: (
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       onClick={() => history.push("/clients")}
          //     >
          //       Our Clients
          //     </a>
          //   ),
          // },
          {
            key: "4",
            label: (
              <a
              target="_blank"
                rel="noopener noreferrer"
                onClick={() => history.push("/testimonial")}
              >
                Testimonial
              </a>
            ),
          },
          {
            key: "5",
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => history.push("/case-studies")}

              >
                Case Studies
              </a>
            ),
          },
        ]} />
         <h2
          onClick={() => history.push("/about")}
          style={{ color: Blockchain ? "var(--textColor)" : null }}
        >
          <span
            className={
              location.pathname === "/about" ? "textStyle" : undefined
            }
          >
            ABOUT
          </span>
        </h2>
        <h2
          onClick={() => history.push("/contactus")}
          style={{ color: Blockchain ? "var(--textColor)" : null }}
        >
          <span
            className={
              location.pathname === "/contactus" ? "textStyle" : undefined
            }
          >
            CONTACT US
          </span>
        </h2>
        <h2
          onClick={() => history.push("/blockchain")}
          style={{ color: Blockchain ? "var(--textColor)" : null }}
        >
          <span
            className={
              location.pathname === "/blockchain" ? "textStyle" : undefined
            }
          >
            BLOCKCHAIN
          </span>
        </h2>
        <DropDown name="" items={[
          // {
          //   key: "1",
          //   label: (
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       // history={history}
          //       onClick={() => history.push("/about")}
          //     >
          //       About
          //     </a>
          //   ),
          // },
          // {
          //   key: "4",
          //   label: (
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       onClick={() => history.push("/bloggrid")}
          //     >
          //       Blog Grids
          //     </a>
          //   ),
          // },
          // {
          //   key: "5",
          //   label: (
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       onClick={() => history.push("/contactus")}
          //     >
          //       Contact Us
          //     </a>
          //   ),
          // },
          // {
          //   key: "6",
          //   label: (
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       onClick={() => history.push("/blockchain")}
          //     >
          //       Blockchain
          //     </a>
          //   ),
          // },
        ]} />
        {/* <h2 onClick={() => history.push("/team")}>OUR TEAM</h2> */}
        {/* <h2>SHOP</h2>
            <h2>BLOG</h2>
            <h2>ELEMENTS</h2> */}
      </div>
      <div className="header_btn">
        {/* <Switch
          className="themeSwitch"
          defaultChecked={theme === "light" ? false : true}
          checkedChildren={
            <MdDarkMode style={{ fontSize: "20px", marginRight: "5px" }} />
          }
          unCheckedChildren={
            <MdOutlineDarkMode
              style={{ fontSize: "20px", marginLeft: "5px" }}
            />
          }
          onChange={() => dispatch(toggleTheme())}
        /> */}
        <button  className="btn" onClick={() => history.push("/contactus")}>
          START NOW
        </button>
        <HeaderDrawer />
      </div>
    </div>
  );
}

export default Header;
