import { Col, Row } from "antd";
import React from "react";
import ProgressBarChart from "./ProgressBarChart";
import Statistic from "./Statistic";

function InnovativeMobileUI() {
  return (
    <div className="parent_container">
      <div className="appDevelopment_container">
        <Row align="middle"
        // gutter={70}
        >
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <ProgressBarChart />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Statistic />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default InnovativeMobileUI;
