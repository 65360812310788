import React from "react";
import Map from "../components/View/Map";
import Layout from "../layout/Layout";
import Form from "../components/View/Form";
import whiteWave from "../assets/wave-separator-white.png";
function ContectUs() {
  return (
    <div className="contectUs_container">
      <Layout>
        <Map />
        {/* <img
          src={whiteWave}
          alt="wave"
          width={"100%"}
          style={{
            position: "relative",
            bottom: "130px",
          }}
        /> */}
        <Form />
      </Layout>
    </div>
  );
}

export default ContectUs;
