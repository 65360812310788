
import { RiStarSFill } from 'react-icons/ri'

const ClientSays = ({item}) => {

    return (
        <div className="client-says">
            <div className='client-img'>
                <img src={item.Image} />
            </div>
            <div className='client-reviews'>
                <div className='caption'>
                    <img src={item.Caption} />
                    <div className='ratings'>
                        <p>5.0</p>
                        <RiStarSFill />
                        <RiStarSFill />
                        <RiStarSFill />
                        <RiStarSFill />
                        <RiStarSFill />
                    </div>
                </div>
                <div className='feedback'>
                    <h3>{item.Heading}</h3>
                    <p>{item.Text}</p>
                    <div className='client-intro'>
                        <h5>{item.Name}</h5>
                        <p>{item.Designation}<span className='blue-font'>{item.Company}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientSays;