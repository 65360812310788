import React from "react";
import { BiChevronRight } from "react-icons/bi";

function DesignCard({ img, text, para }) {
  return (
    <div className="DesignCard">
      <img src={img} alt="image" width={300} />
      <h1>{text}</h1>
      <a style={{ color: "#8b8b8b" }}>April 29, 2019 standard</a>
      <p style={{ width: "90%" }}>{para}</p>
      <div className="icon_div">
        <a>READ MORE</a>
        <BiChevronRight className="icon" color='var(--text)' />
      </div>
    </div>
  );
}

export default DesignCard;
