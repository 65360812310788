import React from "react";
import BlogImage from "../components/View/BlogImage";
import BlogText from "../components/View/BlogText";
import Layout from "../layout/Layout";
function Blog() {
  return (
    <Layout>
      <div className="parent_container waveImageStyle">
        <div className="Blog_container">
          <BlogImage />
          <BlogText />
        </div>
      </div>
    </Layout>
  );
}

export default Blog;
