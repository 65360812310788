import { Col, Row } from "antd";
import React from "react";
import Blockchain from "../../assets/bitcoin.svg";
import BlockchainText from "../View/BlockchainText";

function BlockchainTemplates({ position, icon, title, para, img }) {
  return (
    <div className="parent_container">
      <div className="BlockchainTemplates">
        {position ? (
          <Row align="middle" justify="space-between">
            <Col lg={12} xs={24}>
              <BlockchainText icon={icon} title={title} para={para} />
            </Col>
            <Col lg={12} xs={24}>
              <img src={img} alt="Blockchain" />
            </Col>
          </Row>
        ) : (
          <Row align="middle" justify="space-between">
            <Col lg={12} xs={24}>
              <img src={img} alt="Blockchain" className="leftImage" />
            </Col>
            <Col lg={12} xs={24}>
              <BlockchainText icon={icon} title={title} para={para} />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default BlockchainTemplates;
