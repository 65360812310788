import Layout from "../layout/Layout";
import BannerComponent from "../components/BannerComponent";
import { Row, Col } from "antd";
import SolutionsCards from "../components/Cards/SolutionsCards";
import FeaturesCards from "../components/Cards/FeaturesCards";
import TimeLine from "../components/TImeLine";
import WhyShould from "../components/Cards/WhyShould";
import TestimonialCards from "../components/Cards/TestimonialCards";
import FAQ from "../components/FAQ";
import Form from "../components/View/Form";
import Image1 from "../assets/Game-Development-1-2048x1603.png";
import Image2 from "../assets/Game-Development (1).png";
import Image3 from "../assets/Game-Design.png";
import Image4 from "../assets/Smart-contract-development.png";
import Image5 from "../assets/Token-Issuing.png";
import Image6 from "../assets/Wallet-Integration.png";
import Image7 from "../assets/Community-Building.png";
import Image7a from "../assets/Game-Economy-Consulting.png";
import Image7b from "../assets/Post-Launch-Support.png";
import Image8 from "../assets/Decentralisation.png";
import Image9 from "../assets/In-Game-Tokenization.png";
import Image10 from "../assets/Smart-Contract-Integration.png";
import Image11 from "../assets/Wallet-Integration.png";
import Image11a from "../assets/Community-Building.png";
import Image11b from "../assets/Game-Economy-Consulting.png";
import Image11c from "../assets/Post-Launch-Support.png";
import Image11d from "../assets/Cross-Platform-Compatibility.png";
import Image11e from "../assets/NFT-Integration.png";
import Image11f from "../assets/Security.png";
import Image12 from "../assets/Amy-Jonathan.jpeg";
import Image13 from "../assets/Veronica-Francis.jpeg";
import Image14 from "../assets/Joshua-Anthony.jpeg";
import Image15 from "../assets/Joel-Smith.jpeg";
import Image16 from "../assets/John-Williams.jpeg";
import Image17 from "../assets/Amit-Kulkarni.jpeg";

const BlockChainGameDevelopment = () => {
  const SolutionData = [
    {
      Image: Image2,
      Title: "Game Development",
      Details:
        "We create engaging and immersive blockchain-based games using the latest technologies and platforms.",
    },
    {
      Image: Image3,
      Title: "Game Design",
      Details:
        "Blockchain game design services involve the use of decentralized technology to create unique and secure gaming experiences.",
    },
    {
      Image: Image4,
      Title: "Smart Contract Development",
      Details:
        "We develop secure and reliable smart contracts for in-game transactions and token issuance.",
    },
    {
      Image: Image5,
      Title: "Token Issuing And Management",
      Details:
        "We assist with issuing and managing in-game tokens, including listing on cryptocurrency exchanges.",
    },
    {
      Image: Image6,
      Title: "Wallet Integration",
      Details:
        "We integrate popular blockchain wallets into the game to enable players to manage their in-game assets.",
    },
    {
      Image: Image7,
      Title: "Community Building And Marketing",
      Details:
        "We help build and engage with the game’s community through various online channels and events.",
    },
    {
      Image: Image7a,
      Title: "Game Economy Consulting",
      Details:
        "We provide expert advice on game economy design and balancing to ensure a fair and engaging experience for all players.",
    },
    {
      Image: Image7b,
      Title: "Post-Launch Support",
      Details:
        "We provide ongoing maintenance and updates to assure the smooth operation of the game and its features.",
    },
  ]
  const FeaturesData = [
    {
      Image: Image8,
      Title: "Decentralisation",
      Link1: "Blockchain technology",
      Details:
        "helps in the creation of decentralised games, where players can truly own their in-game assets and have control over their virtual economy.",
      Link2: "",
    },
    {
      Image: Image9,
      Title: "In-Game Tokenization",
      Link1: "",
      Details:
        "The ability to create and manage in-game tokens, which can be used for in-game purchases, trading, and other transactions.",
      Link2: "",
    },
    {
      Image: Image10,
      Title: "Smart Contract Integration",
      Link1: "",
      Details:
        "The use of smart contracts to automate in-game transactions, ensuring security and transparency for players.",
      Link2: "",
    },
    {
      Image: Image11,
      Title: "Wallet Integration",
      Link1: "",
      Details:
        "The ability to integrate popular blockchain wallets into the game, enabling players to easily manage their in-game assets.",
      Link2: "",
    },
    {
      Image: Image11a,
      Title: "Community Building",
      Link1: "",
      Details:
        "A strong focus on building and engaging with the game’s community through various online channels and events.",
      Link2: "",
    },
    {
      Image: Image11b,
      Title: "Game Economy Consulting",
      Link1: "",
      Details:
        "Expert advice on game economy design and balancing to ensure a fair and engaging experience for all players.",
      Link2: "",
    },
    {
      Image: Image11c,
      Title: "Post-Launch Support",
      Link1: "",
      Details:
        "Ongoing maintenance and updates to ensure the smooth operation of the game and its features.",
      Link2: "",
    },
    {
      Image: Image11d,
      Title: "Cross-Platform Compatibility",
      Link1: "",
      Details:
        "Support for various platforms like PC, mobile, and web-based games.",
      Link2: "",
    },
    {
      Image: Image11e,
      Title: "NFT Integration",
      Link1: "",
      Details:
        "Developing games that support NFTs, which are unique digital assets that can traded on a blockchain.",
      Link2: "",
    },
    {
      Image: Image11f,
      Title: "Security",
      Link1: "",
      Details:
        "Implementing robust security measures to protect the game and player data from hacking and other malicious activities.",
      Link2: "",
    },
  ]
  const TimeLineData = [
    {
      Heading: "Strategize",
      Details: "The first step in developing a blockchain game is to come up with a concept. This includes determining the game's theme, mechanics, and features.",
    },
    {
      Heading: "Choosing a Blockchain Platform",
      Details: "Once the concept is in place, the next step is to choose a blockchain platform to build the game on. Popular platforms include Ethereum, EOS, and TRON.",
    },
    {
      Heading: "Game Design and Development",
      Details: "After choosing a blockchain platform, the game design and development process can begin. This includes creating the game's characters, assets, and user interface.",
    },
    {
      Heading: "Smart Contract Development",
      Details: "Smart contracts play a crucial role in blockchain game development as they are responsible for managing in-game assets and transactions. Developers can use programming languages such as Solidity for Ethereum and C++ for EOS to create smart contracts.",
    },
    {
      Heading: "Testing and Deployment",
      Details: "Before the game can be launched, it must be thoroughly tested to ensure that it is functioning correctly and is free of bugs. Once testing is complete, the game can be deployed on the chosen blockchain platform.",
    },
    {
      Heading: "Marketing and Promotion",
      Details: "After the game is launched, it's essential to market and promote it to attract players and increase adoption. This can be done through different channels such as social media, influencer marketing, and online gaming communities.",
    },
  ]
  const WhyShouldData = [
    {
      Numbering: "01",
      Heading: "Expertise in Blockchain Technology",
      Text: "We use the newest technologies to make secure, fun, and transparent games. Blockchain lets players control their in-game assets and record transactions on a blockchain network. Our block chain technology team builds secure, transparent games."
    },
    {
      Numbering: "02",
      Heading: "Quality Game Development",
      Text: "We’re proud to produce high-quality items that exceed client expectations. Our game developers know how to make beautiful, fun games. We polish our games before launch."
    },
    {
      Numbering: "03",
      Heading: "Range of Services",
      Text: "At SDLC Corp, we offer a wide range of services including game design, development, and maintenance. We can help you with every step of the game development process from concept to launch."
    },
  ]

  const ReviewsData = [
    {
      Image: Image12,
      Name: "Amy Jonathan",
      Review:
        "SDLC Corp has been a lifesaver for our company’s blockchain game development. They helped us launch our game on time and under budget. Highly recommend it!",
    },
    {
      Image: Image13,
      Name: "Veronica Francis",
      Review:
        "The team at SDLC Corp is incredibly knowledgeable and skilled in blockchain game development. They helped us create a unique and engaging game that has been a huge hit with players.",
    },
    {
      Image: Image14,
      Name: "Joshua Anthony",
      Review:
        "SDLC Corp was able to take our game to the next level with their expertise in blockchain technology. We couldn’t be happier with the results.",
    },
    {
      Image: Image15,
      Name: "Joel Smith",
      Review:
        "We were impressed by SDLC Corp’s ability to understand our vision for the game and turn it into a reality using blockchain technology. They truly exceeded our expectations.",
    },
    {
      Image: Image16,
      Name: "John Williams",
      Review:
        "Working with SDLC Corp was a pleasure. They were able to bring our blockchain game to market quickly and efficiently. They know their stuff!",
    },
    {
      Image: Image17,
      Name: "Amit Kulkarni",
      Review:
        "SDLC Corp helped us in creating an unique game experience for our users by implementing blockchain technology. Their team is professional and always willing to go the extra mile to ensure our needs.",
    },
  ]

  const FaqData = [
    {
      Question: "What is a blockchain game development company?",
      Answer: "A blockchain game development company is a company specializing in creating decentralized games and applications on blockchain platforms such as Ethereum or EOS."
    },
    {
      Question: "What is the cost of blockchain game development?",
      Answer: "The cost of blockchain game development depends on various factors such as game design, development team size, and platform choice. On average, the cost ranges from $50,000 to $500,000 or more."
    },
    {
      Question: "How does blockchain technology enhance gaming experiences?",
      Answer: "Blockchain technology provides gamers with a secure, decentralized, and transparent gaming environment where they can own their in-game assets and participate in fair, transparent, and verifiable game economies."
    },
    {
      Question: "What are the benefits of partnering with a blockchain game development company?",
      Answer: "By partnering with a blockchain game development company, businesses can leverage the potential of blockchain technology to create new and innovative gaming experiences, tap into new markets, and increase user engagement and retention."
    },
    {
      Question: "What services does a blockchain game development company offer?",
      Answer: "A blockchain game development company offers a range of services, including game design, development, deployment, and maintenance, as well as smart contract development, token economics, and blockchain integration."
    },
    {
      Question: "What types of games can be developed using blockchain technology?",
      Answer: "Blockchain technology is used to develop a wide variety of games, including collectible games, trading games, MMORPGs, and decentralized betting games."
    },
  ]
  return (
    <Layout>
      <div className="block-chain">
        <BannerComponent
          Heading="Blockchain Game Development Company"
          mainHeading="Unlock the Future of Gaming Industry with Our"
          Span="Blockchain Game Development"
          Extras="Company"
          Image={Image1}
        />
        <div className="solution">
          <div className="width75">
            <h2>Blockchain Gaming Solutions</h2>
            <p>Empowering Gaming through Innovative Blockchain Services</p>
            <Row gutter={[40, 40]}>
              {SolutionData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <SolutionsCards
                      Image={data.Image}
                      Title={data.Title}
                      Details={data.Details}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="features">
          <div className="width75">
            <h2>Key Features of Blockchain Development</h2>
            <p>
              Decentralized, Immutable, and Transparent Blockchain Development
            </p>
            <Row gutter={[40, 40]}>
              {FeaturesData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <FeaturesCards
                      Image={data.Image}
                      Title={data.Title}
                      Link1={data.Link1}
                      Details={data.Details}
                      Link2={data.Link2}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="development-process">
          <div className="width75">
            <h2>Development Process of Blockchain Based Game</h2>
            <p>A Complete Guide to Launching Your Own Token Sale.</p>
            <TimeLine Data={TimeLineData} />
          </div>
        </div>
        <div className="leading-choice">
          <div className="width75">
            <h2>Why Should You Choose SDLC Corp</h2>
            <p>The Leading Choice for Next-Generation Gaming Development.</p>
            <Row gutter={[40, 40]}>
              {WhyShouldData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <WhyShould
                      Numbering={data.Numbering}
                      Heading={data.Heading}
                      Text={data.Text}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="testimonial">
          <div className="width75">
            <h2>Testimonials</h2>
            <p>Just Look at People Think About Us</p>
            <Row gutter={[40, 40]}>
              {ReviewsData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <TestimonialCards
                      Image={data.Image}
                      Name={data.Name}
                      Review={data.Review}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="faq">
          <div className="width75">
            <h2>FAQ'S</h2>
            {
              FaqData.map((data, i) => {
                return (
                  <FAQ key={i} Question={data.Question} Answer={data.Answer} />

                )
              })
            }
          </div>
        </div>
        <div className="contact-us">
          <div className="width85">
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlockChainGameDevelopment;