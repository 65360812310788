import CaseStudyCard from "../components/CaseStudyCard";
import Layout from "../layout/Layout";
const CaseStudy = () => {
    const data = [
        {
            project: "Inventory Management System for a Retail Company",
            challenge: "Our client was a retail company with multiple locations, struggling to manage their inventory across all their stores. They needed a solution that would allow them to monitor inventory levels in real-time and automate the reordering process.",
            solutions: "We developed a customized inventory management system that integrated with the client's existing point-of-sale system. The system allowed them to track inventory levels, set reorder points, and generate purchase orders automatically. The system also provided real-time analytics to help the client optimize their inventory management processes.",
            results: "Our solution helped the client reduce stockouts, improve inventory accuracy, and streamline their procurement process. They were able to save time and money by automating previously manual tasks, and their staff had more time to focus on other critical business functions.",
        },
        {
            project: "Mobile App Development for a Healthcare Provider",
            challenge: "Our client was a healthcare provider looking to improve patient engagement and communication. They wanted to develop a mobile app that would allow patients to access their medical records, book appointments, and communicate with their healthcare provider in real-time.",
            solutions: "We developed a customized mobile app that integrated with the client's existing electronic health record system. The app allowed patients to access their medical records securely, book appointments with their healthcare provider, and receive real-time notifications about their healthcare needs. The app also included features for medication reminders, symptom tracking, and telemedicine appointments.",
            results: "Our solution helped the client improve patient engagement and satisfaction, reduce missed appointments, and increase efficiency in their healthcare delivery processes. The app also allowed the client to gather valuable patient data and feedback, which they used to improve their healthcare services further.",
        },
        {
            project: "Custom E-commerce Platform Development for an Online Retailer",
            challenge: "Our client was an online retailer selling a wide variety of products. They needed a customized e-commerce platform that would allow them to manage their online sales, inventory, and shipping more efficiently.",
            solutions: "We developed a customized e-commerce platform that integrated with the client's existing inventory management and shipping systems. The platform included features for product catalog management, order processing, and real-time shipping and delivery tracking. We also developed customized integrations for the client's payment gateways and social media channels.",
            results: "Our solution helped the client streamline their online sales and fulfillment processes, reduce cart abandonment rates, and increase their overall sales revenue. The platform's analytics and reporting features also helped the client optimize their sales strategy and improve customer engagement.",
        },
        {
            project: " Custom CRM Development for a Financial Services Provider",
            challenge: "Our client was a financial services provider with a complex sales and service process. They needed a customized CRM system that would allow them to manage their sales pipeline, customer interactions, and service requests more effectively.",
            solutions: "We developed a customized CRM system that integrated with the client's existing sales and service processes. The system included features for lead management, sales pipeline tracking, customer service management, and real-time reporting. We also developed customized integrations for the client's marketing automation tools and email marketing platforms.",
            results: "Our solution helped the client improve their sales conversion rates, streamline their customer service processes, and increase their customer retention rates. The CRM system's reporting and analytics features also helped the client gain valuable insights into their sales and service performance.",
        },
        {
            project: " Custom IoT Solution Development for a Manufacturing Company",
            challenge: "Our client was a manufacturing company with a complex production process. They needed a customized IoT solution that would allow them to monitor their production equipment in real-time and optimize their manufacturing processes.",
            solutions: "We developed a customized IoT solution that integrated with the client's existing production equipment and control systems. The solution included features for real-time equipment monitoring, predictive maintenance, and automated data analysis. We also developed customized integrations for the client's enterprise resource planning (ERP) system and manufacturing execution system (MES).",
            results: "Our solution helped the client reduce equipment downtime, improve production efficiency, and increase their overall manufacturing output. The IoT solution's analytics and reporting features also helped the client optimize their production processes and improve their overall profitability.",
        },
    ]
    return (
        <Layout>
        <div className="case-study">
            <div className="head">
                <h2>Case Studies</h2>
                <p>Specific projects that we have worked on at Decotechs, outlining the challenges our clients faced, the solutions we provided, and the results achieved.</p>
            </div>
            <div className="card-div">
                <CaseStudyCard Data={data} />
            </div>
        </div>
        </Layout>
    )
}

export default CaseStudy;