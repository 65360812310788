import { Button, Col, Row } from 'antd'
import { useState } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useHistory } from "react-router-dom"
import Alex from '../assets/alex.webp'
import Amin from '../assets/amin.webp'
import bg2 from '../assets/arrow.svg'
import Arrow2 from '../assets/arrowRed.svg'
import Image8 from '../assets/azure.svg'
import bg1 from '../assets/bg-challenge.svg'
import Image7 from '../assets/blockchain.svg'
import Image9 from '../assets/business.svg'
import Caption from '../assets/caption1.svg'
import Clutch from '../assets/clutch.png'
import Cooperate from '../assets/cooperation-icon.svg'
import David from '../assets/david.webp'
import Image15 from '../assets/dedicatedTeam.webp'
import Image13 from '../assets/devops.svg'
import Image4 from '../assets/express.svg'
import Image5 from '../assets/gatsby.svg'
import Firms from '../assets/good-firms.svg'
import Hey from '../assets/hey-practice.svg'
import Ifit from '../assets/ifit.svg'
import Michelle from '../assets/michelle.webp'
import Image11 from '../assets/mobile.svg'
import Image6 from '../assets/mongo.svg'
import Image2 from '../assets/nodejs.svg'
import Outsourcing from '../assets/outsourcing-companies.svg'
import Image14 from '../assets/project.svg'
import Image12 from '../assets/qa.svg'
import Image3 from '../assets/react-native.svg'
import Image1 from '../assets/reactjs.svg'
import Arrow from '../assets/rightarrow.svg'
import Shrad from '../assets/shrad.webp'
import Snafu from '../assets/snafu.svg'
import Theo from '../assets/theo.webp'
import Image10 from '../assets/ui.svg'
import Upwork from '../assets/upwork-logo.svg'
import Expert from '../assets/victoria.webp'
import Wage from '../assets/wagepoint.svg'
import Team from '../assets/you-team-logo.webp'
import Banner from "../components/Banner"
import ClientCarousel from '../components/ClientCarousel'
import DateAndTime from '../components/DateAndTime'

const Services = () => {
  const [situation1, setSituation1] = useState(true);
  const [situation2, setSituation2] = useState(false);
  const [situation3, setSituation3] = useState(false);


  const Data = [
    {
      Image: Alex,
      Caption: Caption,
      Heading: "Decotechs is fully part of our team…",
      Text: "“After working together for 4 months Decotechs is fully part of our team. The service delivered is of very high quality. The execution is quick and includes very close communication. In case of bugs the Decotechs team is super responsive and takes actions also late at night.”",
      Name: "Alexander Timper",
      Designation: "CEO & Founder, ",
      Company: "AreaButler"
    },
    {
      Image: Theo,
      Caption: Snafu,
      Heading: "One of the most productive and responsive teams…",
      Text: "“Decotechs was on top of their project management game — they were one of the most productive and responsive teams we ever worked with.”",
      Name: "Theo Mendez",
      Designation: "Product Manager, ",
      Company: " SNAFU Records"
    },
    {
      Image: Amin,
      Caption: "",
      Heading: "We’ve avoided all the common pitfalls…",
      Text: "“They have a lot of experience, and we’ve avoided all the common pitfalls for this kind of project. Their professionalism, experience, and focus on delivery are excellent.”",
      Name: "Amin Khadempour",
      Designation: "CEO & Founder, ",
      Company: "MyCurrency"
    }
  ]

  const Data2 = [
    {
      Image: David,
      Caption: Hey,
      Heading: "Fast setup",
      Text: "“With Decotechs, we were able to set up a highly skilled IT team in the shortest amount of time.”",
      Name: "David Neuendorf",
      Designation: "CEO, ",
      Company: "HeyPractice.com"
    },
    {
      Image: Michelle,
      Caption: Ifit,
      Heading: "Close-knit team",
      Text: "“Having a team composed and ready for us that already knew how to work together set them apart for me. They worked very closely together with our team and were involved in our planning processes.”",
      Name: "Michelle Bowser",
      Designation: "Director of Technical Operations, ",
      Company: "iFit"
    },
    {
      Image: Shrad,
      Caption: Wage,
      Heading: "We deliver, even on short timelines",
      Text: "“Overall we are very happy with the Decotechs team. We would not have been able to deliver on our short timelines without them showing up for us in the way that they did. Period.”",
      Name: "Shrad Rao",
      Designation: "CEO, ",
      Company: "Wagepoint Inc"
    }
  ]
  const history = useHistory();

  return (
    // <Layout>
    <div className="service-container">
      <Banner />
      <div className="count">
        <Row justify="space-between" gutter={[20, 20]}>
          <Col xs={24} md={12} lg={4}>
            <div className="count-content">
              <h3>87</h3>
              <p>In-house software engineers</p>
            </div>
          </Col>
          <Col xs={24} md={12} lg={4}>
            <div className="count-content">
              <h3>10000</h3>
              <p>Candidates in our database </p>
            </div>
          </Col>
          <Col xs={24} md={12} lg={4}>
            <div className="count-content">
              <h3>5-7<span className="days">days</span></h3>
              <p>To get an expert
                with the needed expertise</p>
            </div>
          </Col>
          <Col xs={24} md={12} lg={4}>
            <div className="count-content">
              <h3>94%</h3>
              <p>Client satisfaction rate</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className='main-carousel'>
        <ClientCarousel Data={Data} />
      </div>
      <div className='solutions'>
        <div className='solutions-content'>
          <h2>Our solutions for your talent gap</h2>
          <h5>Expand your in-house team with our software engineers or let us build a cross-functional dedicated team for you.</h5>
          <h2>Hire top-notch software engineers to work alongside your team</h2>
          <p>From Node.js programmers to cloud engineers to blockchain pros – hire experienced software developers from Decotechs. Our engineering team can help you handle the increased workload, speed up delivery and improve your product quality. We’ll work closely with you to provide the best possible fit.</p>
          <div className='technologies'>
            <Row gutter={[20, 20]} >
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image1} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image2} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image3} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image4} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image5} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image6} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image7} alt='' />
              </Col>
              <Col xs={24} md={12} lg={6} xl={6} className='center'>
                <img src={Image8} alt='' />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className='experts'>
        <div className='experts-content'>
          <h3>Need experts in technologies that are not listed here?</h3>
          <p>Let us know. With our extensive talent database and strong recruitment department, we can find candidates with any skill set.</p>
          <Button onClick={() => history.push("/contactus")}>CONTACT US</Button>
        </div>
      </div>
      <div className='dedicated-team'>
        <div className='dedicated-team-content'>
          <h2>Get a complete “two-pizza” dedicated development team</h2>
          <p>Set up a manageable team to strike the right balance between software quality and delivery speed. We bring in the right people with different skill sets and strong experience in all the areas of software development from business analysis to testing and DevOps. The team will be perfectly coordinated and guided by a senior project manager.</p>
        </div>
        <div className='context'>
          <Row gutter={[10, 60]} justify='space-between'>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image9} alt='' />
                <h3>Business analysts</h3>
              </div>
            </Col>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image10} alt='' />
                <h3>UI/UX designers</h3>
              </div>
            </Col>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image11} alt='' />
                <h3>Web/mobile developers</h3>
              </div>
            </Col>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image12} alt='' />
                <h3>QA specialists</h3>
              </div>
            </Col>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image13} alt='' />
                <h3>DevOps engineers</h3>
              </div>
            </Col>
            <Col xs={24} md={11} lg={7}>
              <div className='augmentation'>
                <img src={Image14} alt='' />
                <h3>Project managers</h3>
              </div>
            </Col>
            <Col xs={24}>
              <div className='leads'>
                <img src={Image15} alt='' />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='offer'>
        <div className='width65'>
          <div className='offer-image'>
            <h2>Want to start a project with Decotechs?</h2>
          </div>
          <div className='offer-content'>
            <p>Tell us about your product and current challenges and we’ll get back to you with our next steps.</p>
            <Button onClick={() => history.push("/contactus")}>GET STARTED</Button>
          </div>
        </div>
      </div>
      <div className='best-fit'>
        <Row gutter={[40, 40]} justify='space-between'>
          <Col xs={24} md={24} lg={24} >
            <div className='best-fit-content'>
              <h2>When we’re the best fit</h2>
              <p>If you find yourself in one of the situations described below, our staff augmentation service is exactly what you need.</p>
            </div>
          </Col>
          <Col xs={24} md={24} lg={8} >
            <Col xs={24} md={24} lg={24} >
              <div className={situation1 ? 'situation white-box' : 'situation'} onClick={() => {
                setSituation1(true)
                setSituation2(false)
                setSituation3(false)
              }} >
                <h5>SITUATION 1</h5>
                <div className='experties'>
                  <p>Lack of expertise</p>
                  <AiOutlineArrowRight />
                </div>
              </div>
            </Col>
            {
              situation1 ?
                <Col xs={24} md={24} lg={24} className='situationresponsive'>
                  <div className='solution'>
                    <p>You{"’"}re working on a new platform but lack developers with specific knowledge to take your project further. Plus, you are facing a tough time finding the right candidates. After all, it{"'"}s not that easy to find people with relevant skill sets and the right attitude to build your product.</p>
                  </div>
                  <div className='solution'>
                    <h6>Solution</h6>
                    <p>We{"'"}ll put you in front of qualified candidates with the exact expertise you need when you need them. All you have to do is specify your requirements and free up time on your calendar to interview our selected candidates personally.</p>
                  </div>
                </Col>
                :
                null
            }
            <Col xs={24} md={24} lg={24} >
              <div className={situation2 ? 'situation white-box' : 'situation'} onClick={() => {
                setSituation1(false)
                setSituation2(true)
                setSituation3(false)
              }}>
                <h5>SITUATION 2</h5>
                <div className='experties'>
                  <p>Staffing shortage</p>
                  <AiOutlineArrowRight />
                </div>
              </div>
            </Col>
            {
              situation2 ?
                <Col xs={24} md={24} lg={24} className='situationresponsive' >
                  <div className='solution'>
                    <p>Your in-house developer has just given you notice and now you{"’"}re behind schedule. With so much going on and so many deadlines to hit, you{"'"}ve started exploring ways to flight-hire developers, but it{"'"}s a lot of hassle. You can{"'"}t afford to spend that much time on hiring.</p>
                  </div>
                  <div className='solution'>
                    <h6>Solution</h6>
                    <p>We can get your project back on track by providing as many developers as you need without even adding a single employee to your payroll. We{"'"}ll work with you throughout the process until your project is successfully completed by your preferred deadline.</p>
                  </div>
                </Col>
                : null
            }
            <Col xs={24} md={24} lg={24} >
              <div className={situation3 ? 'situation white-box' : 'situation'} onClick={() => {
                setSituation1(false)
                setSituation2(false)
                setSituation3(true)
              }}>
                <h5>SITUATION 3</h5>
                <div className='experties'>
                  <p>New priorities</p>
                  <AiOutlineArrowRight />
                </div>
              </div>
            </Col>
            {
              situation3 ?
                <Col xs={24} md={24} lg={15} className='situationresponsive' >
                  <div className='solution'>
                    <p>You {"'"}ve managed to grow your software business quite well in the last few years and you{"'"}re now serving a decent number of clients. You{"'"}re ready to start working on an entirely new product to leapfrog your competition. But who will keep your existing system running smoothly?</p>
                  </div>
                  <div className='solution'>
                    <h6>Solution</h6>
                    <p>Decotechs can provide a self-managed team to support your software and implement new features while you can focus on your new priorities. You{"'"}ll have access to your remote team at any time and we{"'"}ll share timely and transparent updates to keep you informed on our progress.</p>
                  </div>
                </Col>
                : null
            }
          </Col>
          {
            situation1 ?
              <Col xs={24} md={24} lg={15} className='situationdesktop'>
                <div className='solution'>
                  <p>You{"’"}re working on a new platform but lack developers with specific knowledge to take your project further. Plus, you are facing a tough time finding the right candidates. After all, it{"'"}s not that easy to find people with relevant skill sets and the right attitude to build your product.</p>
                </div>
                <div className='solution'>
                  <h6>Solution</h6>
                  <p>We{"'"}ll put you in front of qualified candidates with the exact expertise you need when you need them. All you have to do is specify your requirements and free up time on your calendar to interview our selected candidates personally.</p>
                </div>
              </Col>
              :
              situation2 ?
                <Col xs={24} md={24} lg={15} className='situationdesktop' >
                  <div className='solution'>
                    <p>Your in-house developer has just given you notice and now you{"’"}re behind schedule. With so much going on and so many deadlines to hit, you{"'"}ve started exploring ways to flight-hire developers, but it{"'"}s a lot of hassle. You can{"'"}t afford to spend that much time on hiring.</p>
                  </div>
                  <div className='solution'>
                    <h6>Solution</h6>
                    <p>We can get your project back on track by providing as many developers as you need without even adding a single employee to your payroll. We{"'"}ll work with you throughout the process until your project is successfully completed by your preferred deadline.</p>
                  </div>
                </Col>
                :
                <Col xs={24} md={24} lg={15} className='situationdesktop' >
                  <div className='solution'>
                    <p>You {"'"}ve managed to grow your software business quite well in the last few years and you{"'"}re now serving a decent number of clients. You{"'"}re ready to start working on an entirely new product to leapfrog your competition. But who will keep your existing system running smoothly?</p>
                  </div>
                  <div className='solution'>
                    <h6>Solution</h6>
                    <p>Decotechs can provide a self-managed team to support your software and implement new features while you can focus on your new priorities. You{"'"}ll have access to your remote team at any time and we{"'"}ll share timely and transparent updates to keep you informed on our progress.</p>
                  </div>
                </Col>
          }
        </Row>
      </div>
      <div className='challenge'>
        <Row>
          <Col xs={24} md={24} lg={24} xl={18}>
            <div className='sound-challenge'>
              <img src={bg1} alt='' />
            </div>
          </Col>
          <Col xs={24} md={24} lg={24} xl={6}>
            <div className='arrow'>
              <img src={bg2} alt='' />
            </div>
            <div className='talk-btn'>
              <Button onClick={() => history.push("/contactus")}>Let{"'"}s Talk</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className='type-of-work'>
        <h2>The type of work we get hired for</h2>
        <div className='type-of-work-content'>
          <div className='flex'>
            <div className='image-container'>
              <p>It doesn{"'"}t matter what kind of product you{"'"}re building. Decotechs can implement a project in any industry. Our main strengths lie within the fields of Agile product development, software engineering, and cloud architecture.</p>
              <div className='cooperation'>
                <img src={Cooperate} alt='' />
              </div>
            </div>
            <div className='items-container'>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>MVP development</p>
              </div>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>Splitting monoliths into microservices</p>
              </div>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>Cloud migration assistance</p>
              </div>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>New functionality development</p>
              </div>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>Long-term project development</p>
              </div>
              <div className='items'>
                <img src={Arrow} alt='' />
                <p>Support and maintenance</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='tech-audit'>
        <div className='technical-audit'>
          <h2>Start with a technical audit</h2>
          <p>We’ll review your app from a technical perspective and provide an actionable report from our senior developers on how to fix what needs fixing. This way you can evaluate our skills and decide whether you want to work together going forward.</p>
          <h6>DOWNLOAD TECH AUDIT REPORT <img src={Arrow2} alt='' /></h6>
          <Button onClick={() => history.push("/contactus")}>GET A TECH AUDIT</Button>
        </div>
        {/* <div className='mac-image'>
            <img src={Mac} alt=''/>
          </div> */}
      </div>
      <div className='extend-work'>
        <div className='extend-work-content'>
          <h2>Why extend your team with our help</h2>
          <p>Expertise, cost-efficiency, and speed {"-"} Decotechs offers all of these benefits. We{"'"}re a reliable IT staff augmentation partner for technology companies.</p>
          <div className='qualities'>
            <h3>Save time</h3>
            <p>In the USA, it takes 60 days on average to hire engineers locally. Two months is a long time for projects with tight deadlines. Decotechs can get the right talent for you within 7 days.</p>
          </div>
          <div className='qualities'>
            <h3>Be flexible</h3>
            <p>Short-term or long-term {"-"} you can hire developers for as long as you need them and cut the costs of making full-time hires. Also, you can extend your team whenever required.</p>
          </div>
          <div className='qualities'>
            <h3>Pick out the best</h3>
            <p>Based on your requirements, we provide the most relevant candidates for you to review. You can do your own vetting and interview every candidate to make sure they fit your project.</p>
          </div>
          <div className='qualities'>
            <h3>Reduce costs</h3>
            <p>With us, you eliminate the costs of recruitment, training, office space, software licenses, employee benefits, payroll increments, and logistical expenses. You only pay for actual work.</p>
          </div>
          <div className='qualities'>
            <h3>Meet deadlines</h3>
            <p>By hiring fast and taking control of your staff, you can speed up your development process and meet the deadlines. Our developers will hit the ground running just as soon as they join your team.</p>
          </div>
          <div className='qualities'>
            <h3>Get your own team</h3>
            <p>You know every developer, communicate with them directly and manage their work. We cover all paperwork, team setup, and payroll operations, and facilitate productive relationships.</p>
          </div>
        </div>
      </div>
      <div className='main-carousel'>
        <ClientCarousel Data={Data2} />
      </div>
      <div className='awards'>
        <div className='awards-content'>
          <h2>Awards</h2>
          <div className='awards-img'>
            <img src={Clutch} alt='' />
            <img src={Firms} alt='' />
            <img src={Team} alt='' />
            <img src={Upwork} alt='' />
            <img src={Outsourcing} alt='' />
          </div>
        </div>
      </div>
      <div className='with-decotechs'>
        <div className='flex'>
          <div className='team'>
            <h2>Build a high-performing team with Decotechs</h2>
            <p>Add our talent to your project, or let us do a tech audit to check your source code and get to the root of possible errors that might be affecting your system’s performance.</p>
          </div>
          <div className='btn-container'>
            <Button className='hire' onClick={() => history.push("/contactus")}>HIRE US</Button>
            <p>OR</p>
            <Button className='tech' onClick={() => history.push("/contactus")}>DO A TECH AUDIT</Button>
          </div>
        </div>
      </div>
      {/* <div className='schedule'>
        <div className='schedule-content'>
          <h2>Schedule a call or send us a message</h2>
          <p>We are thrilled about the opportunity to provide software development services for your business</p>
        </div>
        <div className='expert'>
          <div className='flex'>
            <div className='person'>
              <img src={Expert} alt='' />
              <div className='person-details'>
                <h6>Victoria Shvets</h6>
                <p>Digital Business Expert</p>
              </div>
            </div>
            <div className='content'>
              <p>I{"'"}m Digital Business Expert at Decotechs company. Pick a date that works for you to see available times to meet with me and discus your project needs</p>
            </div>
          </div>
        </div>
        <div className='booking'>
          <div className='flex'>
            <h6>Book a call</h6>
            <p>Send a message <AiOutlineArrowRight /></p>
          </div>
          <div className='calendar'>
            <div className='calendar-box'>
              <DateAndTime />
            </div>
          </div>
        </div>
      </div> */}
    </div>
    // </Layout>
  )
}

export default Services;
