import { Col, Row } from "antd";
import React from "react";
import Ethereum from "../../assets/Ethereum.png";
import Solidity from "../../assets/Solidity.png";
import Hyperledger from "../../assets/Hyperledger.png";
import TruffleLogo from "../../assets/Truffle-Logo.png";
import blobHaikei from "../../assets/one.png";
import blobHaikeiTwo from "../../assets/two.png";
import blobHaikeiThree from "../../assets/three.png";
import blobHaikeiFor from "../../assets/for.png";
import { FaEthereum } from "react-icons/fa";
import { SiBinance, SiHyperledger, SiSolidity } from "react-icons/si";

function BlockchainTechnologies() {
  return (
    <div className="parent_container">
      <div className="BlockchainTechnologies">
        <h1>Blockchain Technologies we work with</h1>
        <Row>
          <Col
            lg={6}
            md={12}
            xs={24}
            className="backgroundImage"
            style={{
              backgroundImage: `url(${blobHaikei})`,
            }}
          >
            {/* <img src={Ethereum} alt="Ethereum" width={80} /> */}
            <FaEthereum color="white" size={70} />
            <p>Ethereum</p>
          </Col>
          <Col
            lg={6}
            md={12}
            xs={24}
            className="backgroundImage"
            style={{ backgroundImage: `url(${blobHaikeiTwo})` }}
          >
            {/* <img src={Solidity} alt="Solidity" width={80} /> */}
            <SiSolidity color="white" size={70} />
            <p>Solidity</p>
          </Col>
          <Col
            lg={6}
            md={12}
            xs={24}
            className="backgroundImage"
            style={{ backgroundImage: `url(${blobHaikeiThree})` }}
          >
            {/* <img src={Hyperledger} alt="Hyperledger" width={80} /> */}
            <SiHyperledger color="white" size={70} />
            <p>Hyperledger</p>
          </Col>
          <Col
            lg={6}
            md={12}
            xs={24}
            className="backgroundImage"
            style={{ backgroundImage: `url(${blobHaikeiFor})` }}
          >
            {/* <img src={TruffleLogo} alt="TruffleLogo" width={80} /> */}
            <SiBinance color="white" size={70} />
            <p>Binance</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BlockchainTechnologies;
