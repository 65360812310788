import ClientSays from "./ClientSays";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

const ClientCarousel = ({ Data, i }) => {
    return (
        <div className="clients">
            <Carousel
                showDots
                arrows={false}
                autoPlay
                autoPlaySpeed={2000}
                infinite
                responsive={responsive}>
                {
                    Data?.map(item => (
                        <div className="clients-slider" key={i} >
                            <ClientSays item={item} />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
}

export default ClientCarousel;