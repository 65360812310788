import React from "react";

function BlogGridText() {
  return (
    <div className="blogGridText_Container">
      <h1>Blog Grid</h1>
    </div>
  );
}

export default BlogGridText;
