import { Button } from 'antd'
const PortfolioCards = ({ image, brand, text }) => {
    return (
        <div className="main-card">
            <div className='card-image'>
                <img src={image} alt='' />
            </div>
            <h3>{brand}</h3>
            <p>{text}</p>
            <Button>SEE IN ACTION</Button>
        </div>
    )
}

export default PortfolioCards;