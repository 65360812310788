import React from "react";
import graphicsDesigning from "../assets/graphicsDesigning.svg";
import bitcoin from "../assets/bitcoin.svg";
import development from "../assets/development.svg";
import graphics from "../assets/graphics.svg";
import deploy from "../assets/deploy.svg";

export const WorkflowData = [
  {
    title: "Blockchain",
    para: "Blockchain is modern day technology that can be used and configured for many applications and systems. The introduction of blockchain disrupted the traditional and old school business processes. We provide blockchain enabled services in a diverse zone such as MobileApps, WebApps / dApps, Maintenance.",
    param: "Blockchain",
    image: bitcoin,
    analysis: "Blockchain analysis is the process of studying and analyzing data on a blockchain network to gain insights and knowledge.",
    design: "Blockchain design refers to the creation and development of a blockchain network, including the decisions and choices made regarding the technology's architecture, consensus mechanism, data structure, security features, and overall functionality.",
    development: "Blockchain development refers to the process of creating and implementing blockchain-based applications, platforms, and services.",
    deploy: "Blockchain deployment refers to the process of releasing a blockchain-based application, platform, or service into a production environment where it can be used by end-users.",
    delivery: "Blockchain delivery refers to the use of blockchain technology to secure, manage and track deliveries or shipments",
  },
  {
    title: "Web Development",
    para: "We are providing complete web app solutions, we develop scalable, faster and responsive Web Applications using the most popular technologies. We build both single page and multipage applications and websites using the latest technologies like ReactJS, NodeJS, ExpressJS, MongoDB and many more.",
    param: "Web_Development",
    image: development,
    analysis: "Web development analysis is the process of evaluating the design, functionality, and overall performance of a website",
    design: "Web development design refers to the process of creating and designing a website, including the user interface, layout, and content. This involves a combination of graphic design, HTML, CSS, JavaScript, and other programming languages.",
    development: "Web development refers to the process of building and maintaining websites. This encompasses a wide range of tasks, including front-end development (e.g. HTML, CSS, JavaScript), back-end development (e.g. server-side scripting, database management), and the integration of various technologies and platforms to create a functional website.",
    deploy: "Web development deployment refers to the process of publishing a website or web application live to the internet. This typically involves transferring the website files from a local development environment to a web server.",
    delivery: "The delivery of a web development project typically involves the following steps: Requirements gathering and analysis, Design and prototyping, Development, Testing and Quality Assurance, Deployment and Maintenance and support",
  },
  {
    title: "Mobile Development",
    para: "We are providing highly polished native applications for Android and Cross-platform applications for Android and iOS. We build innovative applications for start-ups and enterprises. We also develop hybrid applications using React Native and Flutter which are the most popular mobile application development frameworks.",
    param: "Mobile_Development",
    image: development,
    analysis: "The analysis phase of mobile app development involves: Identifying the target audience and their needs, Competitor analysis to understand the market and target audience, Defining the goals and objectives of the app, Evaluating the feasibility of the project, Determining the core features and functionalities, Defining the development timeline and budget and Selecting the appropriate technology and platform.",
    design: "The design phase in mobile app development includes the following steps: Creating wireframes and prototypes: to visualize the layout, navigation, and overall user experience of the app, Designing the user interface: to ensure that the app is visually appealing and user-friendly, Designing the app's icons, splash screens and other graphics elements, Defining the app's color scheme, typography, and overall visual style, Ensuring the app is optimized for different screen sizes and devices and Creating a style guide: to ensure consistency in design across all screens of the app.",
    development: "Mobile app development is the process of designing, building and testing software applications for mobile devices, such as smartphones and tablets.",
    deploy: "The deployment phase of mobile app development involves the following steps: App Store submission, Approval process, Deployment, Marketing and promotion and Maintenance and updates",
    delivery: null,
  },
  {
    title: "UI/UX Design",
    para: "We at Decotechs create beautiful and eye catching UI/UX designs. We have Designers that make your website as fresh as your product or service, a color scheme that will match your brand and outshine your competitors. You can choose your colors, themes and anything that can define your Name in the market.",
    param: "UI_UX_Design",
    image: graphicsDesigning,
    analysis: 'UI/UX design analysis is the process of evaluating the user interface (UI) and user experience (UX) of a product, such as a website or mobile app, to improve its design and functionality.',
    design: 'UI/UX design is the process of designing the user interface (UI) and user experience (UX) of a product, such as a website or mobile app. The following steps are involved in UI/UX design: Research, Define requirements, Prototyping, User testing and Visual design',
    development: 'UI/UX design development is the process of transforming the design concepts into a functional product. The following steps are involved in UI/UX design development: Implementation, Testing, Iteration, Finalization, Launch and Maintenance',
  },
  {
    title: "Graphic Design",
    para: "Marketing needs to be captivating but when it's about your business, it's the key. Graphics, animation, 3D work, etc are the game changers to convert audience into customers. Our professionals explicitly provide you that specific brand look to achieve higher marketing goals.",
    param: "Graphic_Design",
    image: graphics,
    analysis: 'Graphic design analysis is the process of evaluating the visual design of a product or project to improve its aesthetic appeal and communication effectiveness',
    design: 'Design is the process of creating visual representations of information to communicate a message or idea. The following steps are involved in graphic design phase: Research, Concept development, Inspiration gathering, Sketching and prototyping, Refinement and Finalization',
    development: 'Graphic design development is the process of transforming a design concept into a final product',
  },
  {
    title: "SEO & Marketing",
    para: "Share your business idea and that's it, leave rest on us. We will professionally market your business to the right target audience which could generate as many leads and recognition as your well-established competetor. Thus, bringing you the most challenging organic traffic and also market your business on giant social media platforms.",
    param: "SEO_&_Marketing",
    image: deploy,
    analysis: 'SEO & Marketing analysis is the process of evaluating the performance and effectiveness of a company search engine optimization (SEO) and marketing strategies. The following steps are involved in SEO & Marketing analysis : Keyword research, Website analysis, Competitor analysis, Traffic analysis and Conversion rate optimization (CRO) analysis',
    design: 'SEO & Marketing Design refers to the process of creating and implementing an effective search engine optimization (SEO) and marketing strategy. The following steps are involved in SEO & Marketing Design: Keyword research, Website design and development, Content creation and optimization, Link building, Marketing strategy and Tracking and reporting',
    development: 'SEO & Marketing Development refers to the process of executing and refining a search engine optimization (SEO) and marketing strategy. The following steps are involved in SEO & Marketing Development: Keyword targeting, On-page optimization, Link building, Marketing tactics and Testing and experimentation. ',
  },
];
