import { Col, Row } from "antd";
import React from "react";
import { AiOutlineMobile } from "react-icons/ai";
import { BiRocket } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";
import { IoDiamondOutline } from "react-icons/io5";

import ModernCards from "../Cards/ModernCards";

function ModernClean() {
  return (
    <div className="parent_container">
      <div className="ModernClean">
        <Row gutter={[30, 30]}>
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <ModernCards
              text="Modern & Clean"
              icon={<IoDiamondOutline size={40} color='var(--text)' />}
              para={
                <p>
                  Top Quality <br /> Clean Design <br /> Bug Free <br /> Premium
                  Support
                </p>
              }
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <ModernCards
              text="Responsive"
              icon={<AiOutlineMobile size={40} color='var(--text)' />}
              para={
                <p>
                  Mobile Responsive
                  <br /> Clean Code <br /> Stylish
                  <br /> Premium
                </p>
              }
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <ModernCards
              text="Highly Customizable"
              icon={<BiRocket size={40} color='var(--text)' />}
              para={
                <p>
                  Customizable
                  <br /> Easy to use
                  <br /> Finishing Work <br /> Premium
                </p>
              }
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={24}>
            <ModernCards
              text="Top Notch Support"
              icon={<BsEmojiSmile size={40} color='var(--text)' />}
              para={
                <p>
                  Available for support
                  <br /> 24/7
                  <br /> Ready to work
                  <br /> Premium Support
                </p>
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ModernClean;
