import React from "react";
import BlockchainTemplates from "../components/Cards/BlockchainTemplates";
import AboutUs from "../components/View/AboutUs";
import Layout from "../layout/Layout";
import publicSvg from "../assets/public.svg";
import WorkflowHeading from "../components/View/WorkflowHeading";
import WorkflowTemplate from "../components/View/WorkflowTemplate";
import man from "../assets/man.png";
import lcd from "../assets/lcd.png";
import pc from "../assets/pc.png";
import rocket from "../assets/rocket.png";
import truck from "../assets/truck.png";
import graphicsDesigning from "../assets/graphicsDesigning.svg";
import Analysis from "../assets/Analysis.svg";
import graphics from "../assets/graphics.svg";
import development from "../assets/development.svg";
import deploy from "../assets/deploy.svg";
import delivery from "../assets/delivery.svg";
import { DiGoogleAnalytics } from "react-icons/di";
import { BiRocket } from "react-icons/bi";

import {
  MdDeliveryDining,
  MdDesignServices,
  MdDeveloperMode,
} from "react-icons/md";
import { GrDeploy } from "react-icons/gr";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { WorkflowData } from "../assets/WorkflowData";

function Workflow({ about }) {
  const { id } = useParams();
  console.log("id:", id);
  return (
    <Layout>
      {WorkflowData.map(
        (data) =>
          data.param === id && (
            <div className="waveImageStyle">
              <div
                className="parent_container"
                // style={{ backgroundColor: "#e4fff1" map}}
              >
                <div className="about_container">
                  <Row align="middle">
                    <Col lg={12} xs={24} className="mobileApp margin">
                      <h1>{data.title}</h1>
                      <p>{data?.para}</p>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <img src={data?.image} alt="image" width={"100%"} />
                    </Col>
                  </Row>
                </div>
              </div>
              <WorkflowHeading />
              {data.analysis && (
                <WorkflowTemplate
                  icon={<DiGoogleAnalytics size={50} color="#0b81d3" />}
                  image={Analysis}
                  position={true}
                  title="Analysis"
                  para={data?.analysis}
                />
              )}

              {data.design && (
                <WorkflowTemplate
                  image={graphics}
                  position={false}
                  icon={<MdDesignServices size={50} color="#0b81d3" />}
                  title="Design"
                  para={data?.design}
                />
              )}

              {data.development && (
                <WorkflowTemplate
                  image={development}
                  position={true}
                  icon={<MdDeveloperMode size={50} color="#0b81d3" />}
                  title="Development"
                  para={data?.development}
                />
              )}

              {data.deploy && (
                <WorkflowTemplate
                  image={deploy}
                  position={false}
                  icon={<BiRocket size={50} color="#0b81d3" />}
                  title="Deploy"
                  para={data?.deploy}
                />
              )}

              {data.delivery && (
                <WorkflowTemplate
                  image={delivery}
                  position={true}
                  icon={<MdDeliveryDining size={50} color="#0b81d3" />}
                  title="Deliver"
                  para={data?.delivery}
                />
              )}
            </div>
          )
      )}
    </Layout>
  );
}

export default Workflow;
