import React from "react";

function ModernCards({ text, icon, para }) {
  return (
    <div className="ModernCards">
      <h1>{text}</h1>
      {icon}
      {para}
    </div>
  );
}

export default ModernCards;
