import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { Button, Col, Layout, Row, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";

const VerticalLayout = ({ children, active }) => {
  const { Sider, Content } = Layout;
  return (
    <div className="v-layout">
      <Layout>
        <Header />
        <Content className="mainContent">{children}</Content>
        <Footer />
      </Layout>
    </div>
  );
};

export default VerticalLayout;
