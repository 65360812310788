import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Layout from "../layout/Layout";
function Custom404() {
  return (
    <Layout>
      <div className="custom404_container">
        <div className="bg_image"></div>
        <div className="bg_text">
          <h1>404 Error</h1>
          <h2>Page not found</h2>
          <p>BACK TO HOMEPAGE</p>
        </div>
      </div>
    </Layout>
  );
}

export default Custom404;
