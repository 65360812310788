
const FeaturesCards = ({Image, Title, Link1, Details, Link2 }) => {
    return(
        <div className="feature-card">
            <img src={Image} />
            <h3>{Title}</h3>
            <p><span className='link'>{Link1}</span> {Details} <span className='link'>{Link2}</span></p>
        </div>
    )
}

export default FeaturesCards;