import { Col, Row } from "antd";
import React from "react";
import { Parallax } from "react-parallax";
import mockup from "../../assets/Simple-Mockup.png";
import iPadPro from "../../assets/iPadPro.jpg";

function ExpensesImg({ position }) {
  return (
    <div>
      <Row>
        <Col xs={24}>
          <Parallax
            className="Parallax_image"
            bgImage={position ? mockup : iPadPro}
            bgImageAlt="mockup"
            // strength={200}
          ></Parallax>
        </Col>
      </Row>
    </div>
  );
}

export default ExpensesImg;
