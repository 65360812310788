import { Progress } from "antd";
import React from "react";

function RoundProgress({ num, percentage, color, text, para }) {
  return (
    <div className="RoundProgress">
      <Progress
        strokeLinecap="butt"
        strokeWidth={10}
        width={150}
        type="circle"
        percent={percentage}
        format={(percent) => `${num}`}
        strokeColor={color}
      />
      <h1>{text}</h1>
      <p>{para}</p>
    </div>
  );
}

export default RoundProgress;
