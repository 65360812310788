import React from "react";
import ProgressChart from "../Charts/Progress";

function ProgressBarChart() {
  return (
    <div className="progress">
      <h1>Innovative <span className="textStyle">Mobile</span> UI</h1>
      <ProgressChart color="#00d1f9" text="Development" parentage="90" />
      <ProgressChart color="var(--primary)" text="Design" parentage="80" />
      <ProgressChart color="#00d1f9" text="Marketing" parentage="70" />
    </div>
  );
}

export default ProgressBarChart;
