import { Col, Row } from "antd";
import React from "react";
import ServicesCard from "../Cards/ServicesCard";
import AppDevelopment from "../../assets/App Development.png";
import ContentManagment from "../../assets/Content Managment.png";
import DataAnalysis from "../../assets/Data Analysis.png";
import Hosting from "../../assets/Hosting.png";
import MarketingPlan from "../../assets/Marketing Plan.png";
import SEOOptimization from "../../assets/SEO Optimization.png";
import { SiAdobeillustrator, SiHive } from "react-icons/si";
import { FaCode, FaMobileAlt } from "react-icons/fa";
import { AiOutlineAntDesign } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { DiIllustrator } from "react-icons/di";

function DataCards() {
  return (
    <div className="parent_container">
      <div className="DataCards">
        <Row gutter={[50, 50]}>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="Blockchain"
              img={<SiHive size={50} color="#0b81d3" />}
              para="Blockchain is modern day technology that can be used and configured for many applications and systems. The introduction of blockchain disrupted the traditional and old school business processes. We provide blockchain enabled services in a diverse zone such as MobileApps, WebApps / dApps, Maintenance."
              param="Blockchain"
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="Web Development"
              img={<FaCode size={50} color="#0b81d3" />}
              para="We are providing complete web app solutions, we develop scalable, faster and responsive Web Applications using the most popular technologies. We build both single page and multipage applications and websites using the latest technologies like ReactJS, NodeJS, ExpressJS, MongoDB and many more."
              param="Web_Development"
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="Mobile Development"
              img={<FaMobileAlt size={50} color="#0b81d3" />}
              para="We are providing highly polished native applications for Android and Cross-platform applications for Android and iOS. We build innovative applications for start-ups and enterprises. We also develop hybrid applications using React Native and Flutter which are the most popular mobile application development frameworks."
              param="Mobile_Development"
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="UI/UX Design"
              img={<AiOutlineAntDesign size={50} color="#0b81d3" />}
              para="We at Decotechs create beautiful and eye catching UI/UX designs. We have Designers that make your website as fresh as your product or service, a color scheme that will match your brand and outshine your competitors. You can choose your colors, themes and anything that can define your Name in the market."
              param="UI_UX_Design"
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="Graphic Design"
              img={<DiIllustrator size={50} color="#0b81d3" />}
              para="Marketing needs to be captivating but when it's about your business, it's the key. Graphics, animation, 3D work, etc are the game changers to convert audience into customers. Our professionals explicitly provide you that specific brand look to achieve higher marketing goals."
              param="Graphic_Design"
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12} xs={24}>
            <ServicesCard
              heading="SEO & Marketing"
              img={<BsInfoCircle size={50} color="#0b81d3" />}
              para="Share your business idea and that's it, leave rest on us. We will professionally market your business to the right target audience which could generate as many leads and recognition as your well-established competetor. Thus, bringing you the most challenging organic traffic and also market your business on giant social media platforms."
              param="SEO_&_Marketing"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DataCards;
