import { Col, Row } from "antd";
import React from "react";
import publicSvg from "../../assets/public.svg";

function WorkflowTemplate({ position, icon, title, para, image }) {
  return (
    <div className="parent_container">
      <div className="WorkflowTemplate">
        {position ? (
          <Row align="middle">
            <Col md={12} xs={24} className="image_div">
              <img src={image} alt="images" />
            </Col>
            <Col md={12} xs={24} className="right">
              <div className="icon">{icon}</div>
              <h1>{title}</h1>
              <p>{para}</p>
            </Col>
          </Row>
        ) : (
          <Row align="middle">
            <Col md={12} xs={24} className="left">
              <div className="icon">{icon}</div>
              <h1>{title}</h1>
              <p>{para}</p>
            </Col>
            <Col md={12} xs={24} className="image_div">
              <img src={image} alt="images" className="image" />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default WorkflowTemplate;
