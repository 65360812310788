import React from "react";
import allStats from "../../assets/all-stats.png";
import salesGraf from "../../assets/sales-graf.png";
import group from "../../assets/group.png";

function Statistic() {
  return (
    <div className="statistic_container">
      <div className="main">
        <img src={group} alt="group" className="group" />
        <img src={salesGraf} alt="salesGraf" className="salesGraf" />
        <img src={allStats} alt="allStats" className="allStats" />
      </div>
    </div>
  );
}

export default Statistic;
