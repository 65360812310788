import React from "react";
// import blog from "../../assets/Blogging.png";
import blog from "../../assets/Simple-Mockup.png";
function BlogImage() {
  return (
    <div className="BlogImage">
      <img src={blog} alt="Blogs" />
    </div>
  );
}

export default BlogImage;
