import React from "react";
import Layout from "../layout/Layout";
import WeBuild from "../components/View/WeBuild";
import Tools from "../components/View/Tools";
import Design from "../components/View/Design";
import Form from "../components/View/Form";
import AppDevelopment from "../components/View/AppDevelopment";
import WhatWeDo from "../components/View/WhatWeDo";
import InnovativeMobileUI from "../components/View/InnovativeMobileUI";
import ExpensesImg from "../components/View/ExpensesImg";
import blackWave from "../assets/wave-separator-footer.png";
import waveWhite from "../assets/wave-separator-white.png";
import CarouselHome from "../components/Carousel/CarouselHome";
// import PortfolioCarousel from "../components/Carousel/PortfolioCarousel";

function TechAndApp() {
  return (
    <Layout>
      <div className="techandapp_container">
        <CarouselHome />
        {/* <PortfolioCarousel /> */}
        {/* <WhatWeDo location="about" /> */}
        <AppDevelopment position="right" location="services" />
        <AppDevelopment position="left" location="services" />
        <AppDevelopment location="blockchain" />
        <Tools />
        <InnovativeMobileUI />
        <Form />
        {/* <WeBuild /> */}
        {/* <img className="whiteWave" src={waveWhite} alt="wave image" width={"100%"} /> */}

        {/* <ExpensesImg position={true} /> */}
        {/* <Design /> */}
        {/* <ExpensesImg position={false} /> */}
        {/* <img src={blackWave} alt="wave" width={"100%"} /> */}
      </div>
    </Layout>
  );
}

export default TechAndApp;
