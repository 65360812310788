import { Col, Row } from "antd";
import React from "react";
import DesignCard from "../Cards/DesignCard";
import videoMarketing from "../../assets/video-marketing.jpg";
import onlineCourse from "../../assets/online-course.jpg";
import invest from "../../assets/invest.jpg";
import AboutTextCard from "../Cards/AboutTextCard";

function AboutAnalysis() {
  return (
    <div className="parent_container">
      <div className="AboutAnalysis" style={{ marginBottom: "100px" }}>
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <AboutTextCard
              text="Data Analysis"
              para="Transform data into actionable insights with our expert data analysis services. Our team utilizes advanced techniques to uncover valuable insights for improved business outcomes. Partner with us for data-driven success."
            />
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <AboutTextCard
              text="Web Expertise"
              para="First, we understand your business nature, missions & goals, then we invent creative & communicative designs to target your audience. We do market research and walk the extra miles to give you out-of-the-box returns on investments. Just connect with us to lead."
            />
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <AboutTextCard
              text="App Development"
              para="Our customized software development can work as influential equipment. We appreciate your market needs and create appropriate applications for your company, whether it's a customer-supporting program or software that keeps your company's proactive internal processes running smoothly."
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutAnalysis;
