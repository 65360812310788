import { Col, Row } from "antd";
import React from "react";
import map from "../../assets/map.PNG";

function Map() {
  return (
    <div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <img src={map} alt="map" width={"100%"} />
        </Col>
      </Row>
    </div>
  );
}

export default Map;
