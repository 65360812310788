import React from "react";
import ParticlesContainer from "../assets/Particles";
import PortfolioCarousel from "../components/Carousel/PortfolioCarousel";
import BlockchainCards from "../components/View/BlockchainCards";
import BlockchainHeader from "../components/View/BlockchainHeader";
import BlockchainMainTemplate from "../components/View/BlockchainMainTemplate";
import BlockchainTechnologies from "../components/View/BlockchainTechnologies";
import Expertise from "../components/View/Expertise";
import Layout from "../layout/Layout";

function BlockchainOld() {
  return (
    <>
      <ParticlesContainer />
      <Layout>
        <div className="parent_container">
          <div className="Blockchain">
            <BlockchainHeader />
            <BlockchainCards />
            <BlockchainMainTemplate />
            <Expertise />
            <BlockchainTechnologies />
          </div>
        </div>
        {/* <div className="blockchainMargin" style={{ position: "relative" }}>
          <PortfolioCarousel />
        </div> */}
        <div className="waveImageStyle"></div>
      </Layout>
    </>
  );
}

export default BlockchainOld;
