import Layout from "../layout/Layout";
import BannerComponent from "../components/BannerComponent";
import { Row, Col, Button } from "antd";
import SolutionsCards from "../components/Cards/SolutionsCards";
import FeaturesCards from "../components/Cards/FeaturesCards";
import WhyShould from "../components/Cards/WhyShould";
import TestimonialCards from "../components/Cards/TestimonialCards";
import FAQ from "../components/FAQ";
import Form from "../components/View/Form";
import Image1 from "../assets/Digital-Transformation-Consulting-Services-2.jpg";
import Image2 from "../assets/Digital-Marketing-Strategy.png";
import Image3 from "../assets/Expertise.png";
import Image4 from "../assets/Insight.png";
import Image5 from "../assets/Innovation-and-Technology-Strategy.png";
import Image6 from "../assets/Automation-of-Business-Processes.png";
import Image7 from "../assets/Management-of-Organizational-Change.png";
import Image7a from "../assets/Excellence-in-Processes.png";
import Image7b from "../assets/Accelerator-for-Digital-Innovation.png";
import Image8 from "../assets/Improved-Resource-Management.png";
import Image9 from "../assets/Enhancing-Talent-Management.png";
import Image10 from "../assets/Superior-Customer-Management.png";
import Image11 from "../assets/Agility-and-Automation.png";
import Image11a from "../assets/Change-Administration-Assistance.png";
import Image11b from "../assets/Long-term-Scalability.png";
import Image12 from "../assets/Emma-Johnson.jpeg";
import Image13 from "../assets/Benjamin-Wilson.jpeg";
import Image14 from "../assets/Ananya-Desai.jpeg";
import Image15 from "../assets/Jihyun-Lee.jpeg";
import Image16 from "../assets/Rishi-Mehta.jpeg";
import Image17 from "../assets/Michael-Johnson.jpeg";

const DigitalTransformation = () => {
    const SolutionData = [
        {
            Image: Image2,
            Title: "Digital Marketing Strategy",
            Details:
                "To accelerate and realise commercial value through digital, we help our clients develop digital enterprises and become transforming factors in their sectors.",
        },
        {
            Image: Image3,
            Title: "Expertise",
            Details:
                "We are your partner in clarifying, designing, and exploring experience transformation – from vision and goals to exceptional user experiences. We will assist you in understanding the demands of your consumers and workers by utilising various approaches.",
        },
        {
            Image: Image4,
            Title: "Insight",
            Details:
                "Using the appropriate technologies, we strive to send proper data to the ideal individuals at the right time. We create business value through improving insights to help organisations achieve their strategic digital transformation goals.",
        },
        {
            Image: Image5,
            Title: "Innovation and Technology Strategy",
            Details:
                "Assist customers in establishing information technology as a trusted business and innovation partner, and collaborate with them to manage their digital-driven technology transformation issues, resulting in future-proof, sustainable results.",
        },
        {
            Image: Image6,
            Title: "Automation of Business Processes",
            Details:
                "The focus is on assisting organisations in creating business value through optimal digital automation. We assist clients in their journey towards productive and efficient business operations that are also innovative and creative.",
        },
        {
            Image: Image7,
            Title: "Management of Organizational Change",
            Details:
                "During the Business Transformation journey we engage, empower, and equip people and organisations using transformational experience, learning & training strategy, agile coaching, leadership, and organizational frameworks.",
        },
        {
            Image: Image7a,
            Title: "Excellence in Processes",
            Details:
                "Focus on operationalizing digital plans through identifying and resolving client business concerns, as well as ensuring processes are optimised to capitalise on the promise of digital transformation.",
        },
        {
            Image: Image7b,
            Title: "Accelerator for Digital Innovation",
            Details:
                "We construct high-performing, cross-functional squads that collaborate in an agile manner to generate prototypes or MVP solutions that are aligned with a business result.",
        },
    ]
    const FeaturesData = [
        {
            Image: Image8,
            Title: "Improved Resource Management",
            Link1: "",
            Details:
                "A consultant for digital transformation can examine your current resources and capabilities. They can utilise this knowledge to curate solutions that improve their efficacy through the use of technology.",
            Link2: "",
        },
        {
            Image: Image9,
            Title: "Enhancing Talent Management",
            Link1: "",
            Details:
                "Digital transformation consultants can help you better manage your human resources. They examine the current competencies of your personnel and help them adapt to a digitally empowered form of work. This approach improves their organisational involvement and professional development chances.",
            Link2: "",
        },
        {
            Image: Image10,
            Title: "Superior Customer Management",
            Link1: "",
            Details:
                "Digital transformation enables your company to develop new solutions to meet your consumers’ shifting needs. A consultant will do a comprehensive analysis of your target audience and build digital tools to help you better understand them. These capabilities will aid in the delivery of a consistent experience.",
            Link2: "",
        },
        {
            Image: Image11,
            Title: "Agility and Automation",
            Link1: "",
            Details:
                "A consulting firm can examine your company’s current process and organisational architecture to discover implementation bottlenecks. They can assist you in incorporating automation techniques to accelerate backdated procedures and increase organisational agility.",
            Link2: "",
        },
        {
            Image: Image11a,
            Title: "Change Administration Assistance",
            Link1: "Digital transformation",
            Details:
                "is difficult because it requires tearing down the status quo and integrating new procedures. A digital management consulting firm can assist you in engaging stakeholders in a way that fosters organisational culture change. This assistance can assist you in improving internal company procedures.",
            Link2: "",
        },
        {
            Image: Image11b,
            Title: "Long-term Scalability",
            Link1: "",
            Details:
                "If you choose to use digital technologies in-house, you may experience some one-time benefits. A digital transformation consultant, on the other hand, may assist you in implementing scalable solutions that produce long-term results.",
            Link2: "",
        },
    ]
    const WhyShouldData = [
        {
            Numbering: "01",
            Heading: "Expertise",
            Text: "Our Digital Transformation Consulting Services offer expertise and knowledge of the most recent digital technology trends. We as consultants, guide organizations and businesses in identifying the right technologies, tools, and strategies to adopt for their specific business needs."
        },
        {
            Numbering: "02",
            Heading: "Efficiency",
            Text: "We help businesses streamline their operations, automate manual tasks, and use data analytics to make their workflows as efficient as possible. This can result in increased efficiency, cost savings, and improved customer experiences."
        },
        {
            Numbering: "03",
            Heading: "Competitive Advantage",
            Text: "In today’s digital age, organizations that do not embrace digital transformation risk falling behind their competitors. We help businesses stay ahead of the curve by identifying opportunities for innovation and helping them implement cutting-edge technologies that can give them a competitive advantage."
        },
    ]

    const ReviewsData = [
        {
            Image: Image12,
            Name: "Harry Thompson",
            Review:
                "I was impressed by the level of expertise and professionalism displayed by the Digital Transformation Consulting team at SDLC Corp. Their digital strategy consulting helped us identify and prioritize key areas for IT transformation, and their digital transformation roadmap was spot on. Their approach to digital change management and enterprise digital transformation was a game changer for us. I highly recommend SDLC Corp for anyone seeking digital transformation solutions.",
        },
        {
            Image: Image13,
            Name: "Charlotte Taylor",
            Review:
                "The team at SDLC Corp is the best digital transformation consulting firm that I’ve worked with. Their digital transformation advisory services were top-notch, and their digital transformation consulting companies provided us with a wealth of resources and insights. The digital transformation planning services were instrumental in helping us to develop a comprehensive digital transformation roadmap, and their digital transformation project management was exceptional.",
        },
        {
            Image: Image14,
            Name: "Saleha Safi",
            Review:
                "SDLC Corp’s Digital innovation consulting was exactly what we wanted to stay ahead of the competition. Their digital business consulting helped us identify new opportunities for growth, and their digital transformation strategy provided a clear path forward. The digital transformation implementation process was seamless, and their digital transformation assessment was incredibly insightful. I would definitely work with them again.",
        },
        {
            Image: Image15,
            Name: "Nova.S",
            Review:
                "The digital transformation consulting team at SDLC Corp helped us navigate the complex world of enterprise digital transformation with ease. Their digital strategy consulting provided us with a clear understanding of what we needed to do to stay ahead of the competition. Their digital transformation solutions were innovative and effective, and their digital change management approach helped us reduce disruptions and assure a seamless transition.",
        },
        {
            Image: Image16,
            Name: "Vivek Verma",
            Review:
                "We engaged SDLC Corp for their digital transformation consulting process, and we were not disappointed. Their digital transformation consulting framework was comprehensive and provided us with a clear understanding of what we needed to do to achieve our digital transformation goals. The digital transformation roadmap and planning services were particularly helpful, and their digital transformation assessment helped us identify areas where we needed to focus our efforts.",
        },
        {
            Image: Image17,
            Name: "Zane Athaide",
            Review:
                "Working with SDLC Corp was a pleasure. Their digital transformation consulting team was knowledgeable, professional, and easy to work with. Their digital transformation planning and implementation services were exceptional, and their digital transformation assessment helped us identify areas where we needed to improve. I highly recommend SDLC Corp for anyone seeking a digital transformation partner.",
        },
    ]

    const FaqData = [
        {
            Question: "What is digital transformation consulting?",
            Answer: "Digital transformation consulting is a process in which a business or organization seeks guidance from experts in technology to develop and implement a comprehensive strategy to adapt to the digital age. The goal is to leverage digital technology to enhance operations, improve customer experiences, and create new revenue streams."
        },
        {
            Question: "What are the benefits of digital transformation consulting?",
            Answer: "Digital transformation consulting offers numerous benefits for businesses and organizations. These benefits include improved efficiency and productivity, enhanced customer experiences, increased revenue, reduced costs, and greater competitiveness. It can also help companies to identify new opportunities and stay ahead of the competition."
        },
        {
            Question: "What is involved in digital transformation consulting?",
            Answer: "Digital transformation consulting typically involves several key steps. These include a comprehensive assessment of the organization’s current digital capabilities and identifying areas for improvement. The consulting firm will then develop a digital strategy and roadmap for implementation, which may involve upgrading technology systems, training staff, and developing new processes. Ongoing monitoring and evaluation are also important to ensure the strategy is achieving the desired outcome."
        },
        {
            Question: "How can I find the right digital transformation consulting firm for my business?",
            Answer: "Finding the right digital transformation consulting firm requires careful research and consideration.Look for companies that have a proven track record of achievement, relevant experience in your industry, and a team of experts with a range of skills and expertise. Check their client testimonials and case studies to ensure their work aligns with your business objectives. You should also consider their approach to project management, communication, and pricing to ensure it aligns with your business needs."
        },
    ]
    return (
        <Layout>
            <div className="block-chain">
                <BannerComponent
                    Heading="Digital Transformation Consulting Services"
                    mainHeading="A Trusted Advisor for Your"
                    Span="Digital Journey"
                    Extras=""
                    Image={Image1}
                />
                <div className="solution">
                    <div className="width75">
                        <h2>Our Digital Transformation Consulting Solutions</h2>
                        <p>Transform Your Business for the Digital Age with Our Consulting Solutions</p>
                        <Row gutter={[40, 40]}>
                            {SolutionData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <SolutionsCards
                                            Image={data.Image}
                                            Title={data.Title}
                                            Details={data.Details}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="features">
                    <div className="width75">
                        <h2>Benefits of Digital Transformation Consulting Services</h2>
                        <p>
                            Digital transformation consulting services can help you achieve the following advantages
                        </p>
                        <Row gutter={[40, 40]}>
                            {FeaturesData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <FeaturesCards
                                            Image={data.Image}
                                            Title={data.Title}
                                            Link1={data.Link1}
                                            Details={data.Details}
                                            Link2={data.Link2}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="leading-choice">
                    <div className="width75">
                        <h2>Why Choose SDLC for Digital Transformation Consulting Services?</h2>
                        <p>Accelerate your digital transformation journey with expert consulting services</p>
                        <Row gutter={[40, 40]}>
                            {WhyShouldData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <WhyShould
                                            Numbering={data.Numbering}
                                            Heading={data.Heading}
                                            Text={data.Text}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="talk-expert">
                    {/* <div className="width75"> */}
                            <h2>Talk to Our Digital Transformation Consulting Expert</h2>
                            <p>Take the first step towards an innovative future and reach out to us for expert guidance on Digital Transformation</p>
                            <Button>Schedule a Free Consultation Call</Button>
                    {/* </div> */}
                </div>
                <div className="testimonial">
                    <div className="width75">
                        <h2>Testimonials</h2>
                        <p>Reviews from Satisfied Customers</p>
                        <Row gutter={[40, 40]}>
                            {ReviewsData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <TestimonialCards
                                            Image={data.Image}
                                            Name={data.Name}
                                            Review={data.Review}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="faq">
                    <div className="width75">
                        <h2>FAQ'S</h2>
                        {
                            FaqData.map((data, i) => {
                                return (
                                    <FAQ key={i} Question={data.Question} Answer={data.Answer} />

                                )
                            })
                        }
                    </div>
                </div>
                <div className="contact-us">
                    <div className="width85">
                        <Form />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DigitalTransformation;