import Layout from "../layout/Layout";
import BannerComponent from "../components/BannerComponent";
import { Row, Col } from "antd";
import SolutionsCards from "../components/Cards/SolutionsCards";
import FeaturesCards from "../components/Cards/FeaturesCards";
import TimeLine from "../components/TImeLine";
import WhyShould from "../components/Cards/WhyShould";
import TestimonialCards from "../components/Cards/TestimonialCards";
import FAQ from "../components/FAQ";
import Form from "../components/View/Form";
import Image1 from "../assets/Blockchain-Development-Company-2048x1586.jpg";
import Image2 from "../assets/Blockchain-consulting.png";
import Image3 from "../assets/Smart-contract-development-2.png";
import Image4 from "../assets/DApp-development-solutions.png";
import Image5 from "../assets/Private-blockchain-development.png";
import Image6 from "../assets/Public-blockchain-development.png";
import Image7 from "../assets/Token-development.png";
import Image7a from "../assets/Wallet-development.png";
import Image7b from "../assets/Blockchain-integration.png";
import Image8 from "../assets/Decentralized-solutions.png";
import Image9 from "../assets/Private-blockchain-development.png";
import Image10 from "../assets/Public-blockchain-development.png";
import Image11 from "../assets/Smart-contract-development-2.png";
import Image11a from "../assets/Consulting-and-advisory-services.png";
import Image11b from "../assets/High-quality-development.png";
import Image12 from "../assets/Jacob-Sam.jpeg";
import Image13 from "../assets/Paul-Watts.jpeg";
import Image14 from "../assets/Amit-Aryan.jpeg";
import Image15 from "../assets/Lucas.jpeg";
import Image16 from "../assets/Ankit-Gupta.jpeg";
import Image17 from "../assets/Isabella.jpeg";
import Image18 from '../assets/Hyperledger.png'
import Image19 from '../assets/Ethereum.png'
import Image20 from '../assets/Stellar.png'
import Image21 from '../assets/Ripple.png'
import Image22 from '../assets/Matic-Polygon.png'
import Image23 from '../assets/EOS-1.png'
import Image24 from '../assets/Dragonchain.png'
import Image25 from '../assets/Hedra-Hashgraph.png'
import Image26 from '../assets/Tezos.png'
import Image27 from '../assets/Corda.png'
import Image28 from '../assets/Tron-1.png'
import Image29 from '../assets/Openchain.png'



const BlockChain = () => {
  const SolutionData = [
    {
      Image: Image2,
      Title: "Blockchain Consulting",
      Details:
        "We engage with customers to understand their needs and goals and give professional recommendations on how blockchain may enhance operations and accomplish their goals.",
    },
    {
      Image: Image3,
      Title: "Smart Contract Development",
      Details:
        "We design and work on Smart Contracts Development, which are self-executing contracts that align with the terms of the agreement written directly into code.",
    },
    {
      Image: Image4,
      Title: "DApp Development Solutions",
      Details:
        "We create decentralized blockchain apps (dapps development) that run on blockchain networks, allowing for secure, transparent, and tamper-proof transactions.",
    },
    {
      Image: Image5,
      Title: "Private Blockchain Development",
      Details:
        "We can help you set up and maintain a private blockchain network, which offers increased security and control over your data.",
    },
    {
      Image: Image6,
      Title: "Public Blockchain Development",
      Details:
        "We have years of experience in building on public blockchain like Ethereum, EOS, TRON, Binance Smart Chain and more.",
    },
    {
      Image: Image7,
      Title: "Token Development",
      Details:
        "We can assist with the development and launch of custom tokens on a blockchain network, that have several possible uses including fundraisers, loyalty programmers, and more, among others.",
    },
    {
      Image: Image7a,
      Title: "Wallet Development",
      Details:
        "We develop custom wallets for storing, sending and receiving digital assets.",
    },
    {
      Image: Image7b,
      Title: "Blockchain Integration",
      Details:
        "We can integrate blockchain technology into existing systems and platforms to improve efficiency and security. We also provide Blockchain solutions for Supply Chain, Gaming, Finance, Healthcare, and others.",
    },
  ];
  const FeaturesData = [
    {
      Image: Image8,
      Title: "Decentralized Solutions",
      Link1: "",
      Details:
        "We specialize in creating decentralized applications and smart contracts that eliminate the need for intermediaries and enhance security.",
      Link2: "",
    },
    {
      Image: Image9,
      Title: "Private Blockchain Development",
      Link1: "",
      Details:
        "We have extensive experience in developing private blockchain solutions for businesses, including supply chain management, digital asset management, and more.",
      Link2: "",
    },
    {
      Image: Image10,
      Title: "Public Blockchain Development",
      Link1: "",
      Details:
        "We also have expertise in developing public blockchain solutions for decentralized finance (DeFi) applications, digital identities, and more.",
      Link2: "",
    },
    {
      Image: Image11,
      Title: "Smart Contract Development",
      Link1: "",
      Details:
        "Our team has experience in developing smart contracts using Solidity, Vyper, and other programming languages, ensuring they are secure and efficient.",
      Link2: "",
    },
    {
      Image: Image11a,
      Title: "Consulting and Advisory Services",
      Link1: "",
      Details:
        "We offer consulting and advisory services that help businesses to understand the potential of blockchain technology and how it can be integrated into their operations.",
      Link2: "",
    },
    {
      Image: Image11b,
      Title: "High-Quality Development",
      Link1: "",
      Details:
        "We have a crew of experienced developers who use the latest tools and technologies to deliver high-quality solutions that meet your business needs.",
      Link2: "",
    },
  ];
  const TimeLineData = [
    {
      Heading: "Planning and Requirements Gathering",
      Details: "For each blockchain project, we customise our services to the individual requirements of our clients. We gather requirements and create a detailed project plan.",
    },
    {
      Heading: "Design and Architecture",
      Details: "We design and architect the blockchain system to meet project goals. This involves choosing a blockchain platform, consensus procedures, and smart contract architecture.",
    },
    {
      Heading: "Development",
      Details: "Our professional blockchain developers build the blockchain network, smart contracts, and user interfaces. Industry-standard development processes provide quality and security.",
    },
    {
      Heading: "Testing and Quality Assurance",
      Details: "We thoroughly test the software solution to ensure it functions as expected and meets all requirements. We also conduct security audits to identify and mitigate potential vulnerabilities.",
    },
    {
      Heading: "Deployment",
      Details: "Once the software is fully tested and approved, we deploy it to the desired blockchain network.",
    },
    {
      Heading: "Maintenance and Support",
      Details: "We provide ongoing maintenance and support to ensure the smooth operation of the blockchain network and to address any issues that may arise.",
    },
    {
      Heading: "Continual improvement",
      Details: "We are constantly monitoring the blockchain market for new developments and updates, and work to improve our services and solutions accordingly.",
    },

  ]
  const WhyShouldData = [
    {
      Numbering: "01",
      Heading: "Expertise",
      Text: "Our team of developers and engineers have a deep understanding of blockchain technology and have experience working with a variety of different platforms and protocols.",
    },
    {
      Numbering: "02",
      Heading: "Innovation",
      Text: "We stay up-to-date with the latest developments in the blockchain space and are constantly exploring new ways to leverage this technology to solve real-world problems.",
    },
    {
      Numbering: "03",
      Heading: "Customization",
      Text: "We understand that every business has unique needs, which is why we think it’s important to get to know our clients well so we can meet their demands and tailor our solutions to meet their specific needs.",
    },
    {
      Numbering: "04",
      Heading: "Scalability",
      Text: "We design our solutions to be scalable, so you can easily expand your blockchain application as your business grows.",
    },
    {
      Numbering: "05",
      Heading: "Security",
      Text: "We place a strong emphasis on security, and our solutions are designed to be secure and tamper-proof. We are committed to delivering high-quality blockchain development services that drive innovation and business growth.",
    },
  ];
  const ReviewsData = [
    {
      Image: Image12,
      Name: "Teresa Butler",
      Review: "We hired the blockchain development company to help us create a decentralized platform for our supply chain management, and they exceeded our expectations. Their team was knowledgeable, responsive, and delivered the project on time and within budget. Highly recommend them!",
    },
    {
      Image: Image13,
      Name: "Idon Flores",
      Review: "The blockchain development company helped us create a secure and transparent voting system for our organization. Their expertise in smart contract development was invaluable, and we couldn’t be happier enough with the results. We will definitely be working with them again in the future. They are no.1 in the list of the best blockchain developers now",
    },
    {
      Image: Image14,
      Name: "Nicole Ward",
      Review: "We were looking for a blockchain solution to help us with our digital asset management, and the blockchain development company was the perfect fit. Their team was professional, efficient, and the end result was exactly what we needed. We highly recommend their services.",
    },
    {
      Image: Image15,
      Name: "Lucas",
      Review:
        "Working with SDLC blockchain developers was a great experience. They were able to explain complex technical concepts in a way that was easy to understand, and they delivered a blockchain solution that met all of our requirements.",
    },
    {
      Image: Image16,
      Name: "Ankit Gupta",
      Review:
        "SDLC Corp. assisted us in establishing a distributed infrastructure for our customer loyalty programme. They were able to develop a solution that was both reliable and safe with the assistance of their highly skilled personnel. They are one of the top blockchain development companies, and we have no hesitation in recommending their work.",
    },
    {
      Image: Image17,
      Name: "Isabella",
      Review:
        "We were looking for a team of blockchain developers who could help us integrate blockchain technology into our existing systems. The team we worked with was beneficial and responsive, and they delivered a solution that exceeded our expectations.",
    },
  ];
  const FaqData = [
    {
      Question: "What are the advantages of initial coin offerings?",
      Answer: "Easy to get money: Initial coin offerings are the best way for blockchain-based businesses to raise money (ICO). International reach: With ICO, you can easily reach the market all over the world."
    },
    {
      Question: "How can a blockchain development company help my business?",
      Answer: "A blockchain development company can help your business by creating custom blockchain solutions tailored to your specific needs. They can assist with everything from initial concept development, project management to implementation and ongoing maintenance."
    },
    {
      Question: "What are some common use cases for blockchain technology?",
      Answer: "Blockchain technology is commonly used for financial transactions, supply chain management, and digital identity verification. Other potential use cases include voting systems, medical record keeping, and real estate ownership."
    },
    {
      Question: "What are the advantages of using blockchain technology?",
      Answer: "Blockchain technology offers several benefits, including increased security, transparency, and efficiency. It also eliminates the need for intermediaries and reduces the potential for fraud."
    },
    {
      Question: "Are you experienced in any specific blockchain platform?",
      Answer: "Yes, we have experience in several blockchain platforms like Ethereum, EOS, Hyperledger and others. We will suggest the best suitable platform for your business based on your requirements and use case."
    },
  ]
  return (
    <Layout>
      <div className="block-chain">
        <BannerComponent
          Heading="Blockchain Development by Decotechs"
          mainHeading="Experience the"
          Span="Blockchain Revolution"
          Extras="Blockchain Revolution"
          Image={Image1}
        />
        <div className="solution">
          <div className="width75">
            <h2>Blockchain Development Services</h2>
            <p>At our blockchain development company, we offer a wide range of services to help businesses and organizations harness the power of blockchain technology.</p>
            <Row gutter={[40, 40]}>
              {SolutionData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <SolutionsCards
                      Image={data.Image}
                      Title={data.Title}
                      Details={data.Details}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="features">
          <div className="width75">
            <h2>Key Features of Blockchain Development</h2>
            <p>
              Decentralized, Immutable, and Transparent Blockchain Development
            </p>
            <Row gutter={[40, 40]}>
              {FeaturesData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <FeaturesCards
                      Image={data.Image}
                      Title={data.Title}
                      Link1={data.Link1}
                      Details={data.Details}
                      Link2={data.Link2}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="development-process">
          <div className="width75">
            <h2>Our Blockchain Software Development Process</h2>
            <p>Exploring the process of software Development.</p>
            <TimeLine Data={TimeLineData} />
          </div>
        </div>
        <div className="development-platforms">
          <div className="width75">
            <h2>Blockchain Development Platforms</h2>
            <p>Transforming Businesses, Building the future</p>
            <Row gutter={[40, 40]}>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image18} alt='' />
                  <h3>Hyperledgure</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image19} alt='' />
                  <h3>Ethereum</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image20} alt='' />
                  <h3>Stellar</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image21} alt='' />
                  <h3>Ripple</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image22} alt='' />
                  <h3>Matic Polygon</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image23} alt='' />
                  <h3>EOS</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image24} alt='' />
                  <h3>Dragonchain</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image25} alt='' />
                  <h3>Hedra Hashgraph</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image26} alt='' />
                  <h3>Tezos</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image27} alt='' />
                  <h3>Corda</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image28} alt='' />
                  <h3>Tron</h3>
                </div>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <div className="development-platforms-card">
                  <img src={Image29} alt='' />
                  <h3>OpenChain</h3>
                </div>
              </Col>
            </Row>

          </div>

        </div>
        <div className="leading-choice">
          <div className="width75">
            <h2>Why Should You Choose SDLC Corp for Your Blockchain Development?</h2>
            <p>SDLC Corp is committed to offering the best blockchain development services. We can help you bring your blockchain idea to life with our experienced engineers. Here are few reasons why you should choose SDLC Corp for your blockchain development needs:</p>
            <Row gutter={[40, 40]}>
              {WhyShouldData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <WhyShould
                      Numbering={data.Numbering}
                      Heading={data.Heading}
                      Text={data.Text}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="testimonial">
          <div className="width75">
            <h2>Testimonials</h2>
            <p>Reviews from Satisfied Customers</p>
            <Row gutter={[40, 40]}>
              {ReviewsData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <TestimonialCards
                      Image={data.Image}
                      Name={data.Name}
                      Review={data.Review}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="faq">
          <div className="width75">
            <h2>FAQ'S</h2>
            {
              FaqData.map((data, i) => {
                return (
                  <FAQ key={i} Question={data.Question} Answer={data.Answer} />

                )
              })
            }
          </div>
        </div>
        <div className="contact-us">
          <div className="width85">
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlockChain;
