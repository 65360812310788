import { Carousel } from "antd";
import React from "react";
import WeBuild from "../View/WeBuild";
const contentStyle = {
  height: "80vh",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const CarouselHome = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Carousel 
    // autoplay
      afterChange={onChange}>
      <div style={contentStyle}>
        <WeBuild />
      </div>
      <div style={contentStyle}>
        <WeBuild />
      </div>
      <div style={contentStyle}>
        <WeBuild />
      </div>
    </Carousel>
  );
};

export default CarouselHome;
