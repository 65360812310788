import React from "react";
import DesignCard from "../Cards/DesignCard";
import onlineCourse from "../../assets/online-course.jpg";
import invest from "../../assets/invest.jpg";
import videoMarketing from "../../assets/video-marketing.jpg";
import graphics from "../../assets/graphics.jpg";
import webDesign from "../../assets/webDesign.jpg";
import digitalArt from "../../assets/digitalArt.jpg";
import rafaelPol from "../../assets/rafaelPol.jpg";

import { Col, Row } from "antd";

function GridCards() {
  return (
    <div className="parent_container">
      <div className="gridCards_container">
        <Row gutter={[20, 20]}>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Hello world!"
              img={onlineCourse}
              para="A 'Hello, World!' program is generally a computer program that ignores any input and outputs or displays a message similar to 'Hello, World!'..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Linux"
              img={invest}
              para="Linux.com is an official and fundamental resource that provides open source information about Linux. Here you can find the latest news..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Planet Kernel"
              img={videoMarketing}
              para="This is another kernel focussed website where you will get all the latest updates on the kernel. You will also get the summit and events..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Web Development"
              img={graphics}
              para="If you work on a large-scale application, eventually, you will find yourself creating the same User Interface (UI) piece (or with slightly different functionality) repeatedly...."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Quote"
              img={webDesign}
              para="A 'Hello, World!' program is generally a computer program that ignores any input and outputs or displays a message similar to 'Hello, World!'..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Rich Layout"
              img={digitalArt}
              para="I have been working on a project which I needed to pretty print some data. That was how I discovered Rich, a Python library for rich text and beautiful formatting in the terminal..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Block Quotes"
              img={videoMarketing}
              para="Medium offers many sleek article formatting features. One of the most useful is the addition of stylized quotes to an article. There are two types of Medium quotes..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Design"
              img={onlineCourse}
              para="Secret Source The best design inspiration — expertly curated for you. Muzli is a new-tab Chrome extension that instantly delivers relevant design..."
            />
          </Col>
          <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <DesignCard
              text="Java"
              img={invest}
              para="Java is perfect for developing large web applications too because of its ability to communicate with a large number of systems. Services like peer-web services..."
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default GridCards;
