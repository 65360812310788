import { Col, Row } from "antd";
import React from "react";

import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";

import appstore_button from "../assets/appstore_button.png";
import googleplay_button from "../assets/googleplay_button.png";

import { useHistory } from "react-router-dom";
import blackWave from "../assets/wave-separator-footer.png";
// import waves from "../assets/wave.png";
import Logo from "../assets/Logo.png";
import { BiChevronRight } from "react-icons/bi";

function Footer() {
  const history = useHistory();
  return (
    <>
      {/* <img
        src={waves}
        alt="wave"
        width={"100%"}
        style={{ marginBottom: "-17px", backgroundColor: "var(--background)", marginTop: '-2px' }}
      /> */}
      <div
        className="parent_container"
        style={{
          background: "rgb(3,62,111)",
          background: "linear-gradient(0deg, rgba(3,62,111,1) 0%, rgba(81,190,237,1) 100%)",
          // position: "relative",
        }}
      >
        {/* <img src={waves} alt="wave" width={"100%"} className="waveImage" /> */}
        <div className="footer_container">
          <Row gutter={30} className="footer_row">
            <Col xl={12} lg={12}>
              <div className="footer_logo">
                <img
                  src={Logo}
                  alt="LOGO"
                  width={150}
                  onClick={() => history.push("/")}
                />
                <p>
                  Decotechs team consists of highly knowledgeable and
                  <br/>
                  well-experienced developers. Our Expert developers are always
                  <br/>
                  ready to provide high-quality services on time.
                </p>
              </div>
            </Col>
            <Col xl={12} lg={12}>
              <div className="copyright" style={{ display: 'flex', justifyContent: 'end', paddingTop: '2rem' }}>
                <div >
                  <a href="https://www.facebook.com/Decotechsdotcom">
                    <FaFacebookF size={40} className="icon" />
                  </a>
                  <a href="https://www.linkedin.com/company/decotechs">
                    <AiFillLinkedin size={40} className="icon" />
                  </a>
                  <a href="https://www.instagram.com/decotechs/">
                    <AiOutlineInstagram size={40} className="icon" />
                  </a>
                </div>
              </div>
            </Col>
            {/* quickLinks */}
            {/* <Col lg={6} xs={24}>
              <h1>QuickLinks</h1>
              <div className="quickLinks">
                <div className="split_div">
                  <div className="icon_div">
                    <p onClick={() => history.push("/about")}>About</p>
                  </div>
                  <div className="icon_div">
                    <p onClick={() => history.push("/our-services")}>
                      Our Services
                    </p>
                  </div>
                  <div className="icon_div">
                    <p onClick={() => history.push("/clients")}>Our Clients</p>
                  </div>
                </div>
                <div className="icon_div">
                  <p onClick={() => history.push("/our-portfolio")}>Portfolio</p>
                </div>
                <div className="icon_div">
                  <p onClick={() => history.push("/contactus")}>Contact Us</p>
                </div>
                <div className="icon_div">
                  <p onClick={() => history.push("/testimonial")}>Testimonial</p>
                </div>
                <div className="icon_div">
                  <p onClick={() => history.push("/case-studies")}>Case Studies</p>
                </div>
                <div className="icon_div">
                  <p onClick={() => history.push("/blockchain")}>
                    Blockchain
                  </p>
                </div>

              </div>
            </Col> */}
            {/* <Col xl={6} lg={6}>
              <div className="tweet">
                <h1>Last Tweet</h1>
                <p>
                  about 3 months ago Mid-year SALES keep going. Up to 40% off!!
                </p>
              </div>
            </Col> */}
            {/* <Col lg={6} xs={24}>
              <div className="appStore">
                <h1>Download Our App</h1>
                <h3>Available on Google Play and App Store.</h3>
                <div>
                  <img
                    src={googleplay_button}
                    alt="googleplay_button"
                    width={100}
                    height={40}
                  />
                  <img
                    src={appstore_button}
                    alt="appstore_button"
                    width={100}
                    height={40}
                  />
                </div>
              </div>
            </Col> */}
            <Col xs={24}>
              <div className="copyright" style={{display: 'flex', justifyContent: 'center'}}>
                <p>© Copyright Decotechs powerded by Laptop Store LLC.</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Footer;

// remove from footer
{/* <BsArrowRightShort /> */ }
{/* <div className="split_div"> */ }
{/* <div className="icon_div">
                    <BsArrowRightShort />
                    <p onClick={() => history.push("/bloggrid")}>Blog Grids</p>
                  </div> */}
{/* <div className="icon_div">
                    <BsArrowRightShort />
                    <p onClick={() => history.push("/team")}>Our Team</p>
                  </div> */}
{/* </div> */ }