
const WhyShould = ({Numbering, Heading, Text}) => {
    return(
        <div className="why-should">
            <h1>{Numbering}</h1>
            <h3>{Heading}</h3>
            <p>{Text}</p>
            
        </div>
    )
}

export default WhyShould;