import { Col, Row } from "antd";
import React from "react";
import BlockchainCard from "./BlockchainCard";
import people from "../../assets/cubix-chain-operations.svg";
import copy from "../../assets/copy.svg";
import party from "../../assets/cubix-chain-party-onboarding.svg";
import mice from "../../assets/mice.svg";
import mobile from "../../assets/mobile.svg";
import paper from "../../assets/paper.svg";

function BlockchainCards() {
  return (
    <div className="parent_container">
      <div className="BlockchainCards">
        <Row gutter={[30, 30]}>
          <Col xs={24}>
            <h1>Your business powered by blockchain</h1>
            <p>
              Business registration to asset registration, everything is wizard
              driven
            </p>
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={copy}
              title="Party onboarding"
              para="Allows enterprises to create Wallet which is an area where all digital
            addresses of this entity are kept."
            />
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={party}
              title="Digital asset onloading"
              para="Define Asset by its own Unique-ID, Unique-Attributes, Asset’s Original Physical File and the file gets stored in IPFS."
            />
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={mice}
              title="Operation"
              para="Transfer that can be performed on a digital asset or among registered nodes on the go."
            />
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={mobile}
              title="Smart contracts templates"
              para="Smart Contracts Marketplace can list templates uploaded by other developers, and one can choose a particular template."
            />
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={people}
              title="Drag & drop creator"
              para="Edit the rules or add new ones in an existing contract via drag n drop visual composer of smart contracts."
            />
          </Col>
          <Col lg={8} md={12} sm={12} xs={24}>
            <BlockchainCard
              img={paper}
              title="Transactions explorer"
              para="This unit allows people to see their transaction from transaction-ID. It helps people to search by Smart-Contract ID."
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BlockchainCards;
