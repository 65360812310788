import { Collapse, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const FAQ = ({Question, Answer, key}) => {
  const { Panel } = Collapse;
  return (
    <div>
      <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel header={Question} key={key}>
          <p>
            {Answer}
          </p>
        </Panel>
      </Collapse>
      {/* <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel
          header="How does blockchain technology enhance gaming experiences?"
          key="1"
        >
          <p>
            The cost of blockchain game development depends on various factors
            such as game design, development team size, and platform choice. On
            average, the cost ranges from $50,000 to $500,000 or more.
          </p>
        </Panel>
      </Collapse>
      <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel
          header="How does blockchain technology enhance gaming experiences?"
          key="1"
        >
          <p>
            Blockchain technology provides gamers with a secure, decentralized,
            and transparent gaming environment where they can own their in-game
            assets and participate in fair, transparent, and verifiable game
            economies.
          </p>
        </Panel>
      </Collapse>
      <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel
          header="What are the benefits of partnering with a blockchain game development company?"
          key="1"
        >
          <p>
            By partnering with a blockchain game development company, businesses
            can leverage the potential of blockchain technology to create new
            and innovative gaming experiences, tap into new markets, and
            increase user engagement and retention.
          </p>
        </Panel>
      </Collapse>
      <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel
          header="What services does a blockchain game development company offer?"
          key="1"
        >
          <p>
            A blockchain game development company offers a range of services,
            including game design, development, deployment, and maintenance, as
            well as smart contract development, token economics, and blockchain
            integration.
          </p>
        </Panel>
      </Collapse>
      <Collapse
        collapsible="header"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
      >
        <Panel
          header="What types of games can be developed using blockchain technology?"
          key="1"
        >
          <p>
            Blockchain technology is used to develop a wide variety of games,
            including collectible games, trading games, MMORPGs, and
            decentralized betting games.
          </p>
        </Panel>
      </Collapse> */}
    </div>
  );
};

export default FAQ;
