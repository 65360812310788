import { ClockCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { useEffect,useState } from "react";

const TimeLine = ({Data}) => {

  const [width ,setWidth] = useState(window.innerWidth)
  useEffect(() => {

   window.addEventListener("resize" , () => {
    setWidth(window.innerWidth)
   })

  },[])

  return (
    <div className="timeline-container">
      <Timeline mode={width < 700 ? "left" : "alternate"}>

        {
          Data?.map((item,index) => (
        <Timeline.Item dot={<div className="timeline-circle" />}>
          <div className={width < 700 ? "timeline-right" : index %2 === 0 ? "timeline-right" : "timeline-left"}>
            <h1>{item.Heading}</h1>
            <h4>{item.Details}</h4>
          </div>
        </Timeline.Item>
          ))
        }
      </Timeline>
    </div>
  );
};

export default TimeLine;
