import React from "react";

function OurClients() {
  return (
    <div className="ourClients">
      <h1>Our Clients</h1>
      <h2>
        <span className="textStyle">Awesome</span> People We Worked With
      </h2>
      <p>
        The clients to whom we served Our Professional Web developers assure the
        high quality standards while making websites
      </p>
    </div>
  );
}

export default OurClients;
