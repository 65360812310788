import React from "react";
import PortfolioData from "../components/View/PortfolioData";
import Layout from "../layout/Layout";

function Portfolio() {
  return (
    <Layout>
      <div className="portfolio_text">
        <h1>
          {/* Portfolio <span className="textStyle">Filtered</span> */}
          Portfolio 
        </h1>
      </div>
      <PortfolioData />
    </Layout>
  );
}

export default Portfolio;
