import React from "react";

function BlogText() {
  return (
    <div className="BlogText">
      <h1>Hello World</h1>
      <p>April 29, 2019</p>
      <h2>
        The React team is making a final pass before fully switching over but —
        at the time of writing — all of the commonly used modern APIs are
        documented, making it the best way to learn the framework. React
        veterans out there know that to be a true React grandmaster, you need to
        follow every React team member on Twitter to make sure you don’t miss
        any precious nuggets of information. Not anymore! All that esoteric
        knowledge is now available in an easy-to-read format. Ferocious kids
        coming from behind will take your jobs thanks to the new React docs!
        Your only chance to stay ahead is to humble yourself, sit down, and read
        every single word in the new docs. But fear not, for I have selflessly
        taken one for the team. I went through the docs from top to bottom and
        broke it all down for you. Here’s what I’ve learned: 1. React was
        originally created by Jordan Walke. Today, Jordan Walke doesn’t work at
        Facebook anymore. His recent tweets suggest that he’s really into OCaml,
        a functional language. I sincerely appreciate that he used his
        functional mind to improve web development. 2. React has 10 team
        members. The only way to join the React team is to save the life of a
        React team member. So far, this has only happened once, when Dan Abramov
        was caught in a while-loop. 3. The docs use CodeSandbox for their
        interactive code examples. The docs also mention that React is supported
        by several other sandbox services. They explicitly name CodeSandbox,
        StackBlitz, and CodePen. 4. The new docs take a page from Vue on
        explaining the different use cases of React. From “adding some
        interactivity” to “more complex apps” and even “large apps”, the new
        docs do a great job at mapping out the different levels of React usage.
        5. Adding React to a simple HTML page is easy. It doesn’t even require a
        build step. Just add React as a script tag, write a component, and
        render it on a root. If you want to add JSX support, you can set up a
        simple build step with babel-cli. 6. You can have multiple React roots.
        Again, this is useful if you want to add a few interactive components to
        a static HTML page. You can simply create a root for each component,
        instead of creating a root for the full document. Yet, the docs don’t
        clarify if it’s possible to share information between components on
        different roots. 7. The new docs still recommend the godawful Create
        React App. Create React App might be convenient and unopinionated, but
        it’s a pile of outdated trash that never fixes important bugs. Just take
        a look at the Github issues if you don’t believe me. Luckily, the docs
        also recommend popular alternatives like Vite and Parcel. 8. Next.js is
        recommended for production-ready projects. No surprises here. Next.js is
        good, especially now that it allows more flexibility on page layouts.
        Other recommendations are Gatsby, Remix, and Razzle. 9. There’s a fine
        list of tool recommendations to build your own toolchain. If you want to
        fly solo, the docs suggest a sensible and trendy list of options. A
        pleasing surprise to me was that there’s no mention of Lerna, which
        makes sense after recent debacles. Instead, the recommendations for
        monorepos are Nx and Turborepo. 10. The recommended editors are VS Code,
        WebStorm, Sublime Text, and Vim. No big surprises there. I would only
        add that any JetBrains editor is up to the task, not only WebStorm.
        Also, Sublime Text is a meme, Dan Abramov only mentioned it because he
        still feels bad about that time on a conference talk when the Sublime
        Text pop-up for buying a licence appeared on screen. The React team is
        making a final pass before fully switching over but — at the time of
        writing — all of the commonly used modern APIs are documented, making it
        the best way to learn the framework. React veterans out there know that
        to be a true React grandmaster, you need to follow every React team
        member on Twitter to make sure you don’t miss any precious nuggets of
        information. Not anymore! All that esoteric knowledge is now available
        in an easy-to-read format. Ferocious kids coming from behind will take
        your jobs thanks to the new React docs! Your only chance to stay ahead
        is to humble yourself, sit down, and read every single word in the new
        docs. But fear not, for I have selflessly taken one for the team. I went
        through the docs from top to bottom and broke it all down for you.
        Here’s what I’ve learned: 1. React was originally created by Jordan
        Walke. Today, Jordan Walke doesn’t work at Facebook anymore. His recent
        tweets suggest that he’s really into OCaml, a functional language. I
        sincerely appreciate that he used his functional mind to improve web
        development. 2. React has 10 team members. The only way to join the
        React team is to save the life of a React team member. So far, this has
        only happened once, when Dan Abramov was caught in a while-loop. 3. The
        docs use CodeSandbox for their interactive code examples. The docs also
        mention that React is supported by several other sandbox services. They
        explicitly name CodeSandbox, StackBlitz, and CodePen. 4. The new docs
        take a page from Vue on explaining the different use cases of React.
        From “adding some interactivity” to “more complex apps” and even “large
        apps”, the new docs do a great job at mapping out the different levels
        of React usage. 5. Adding React to a simple HTML page is easy. It
        doesn’t even require a build step. Just add React as a script tag, write
        a component, and render it on a root. If you want to add JSX support,
        you can set up a simple build step with babel-cli. 6. You can have
        multiple React roots. Again, this is useful if you want to add a few
        interactive components to a static HTML page. You can simply create a
        root for each component, instead of creating a root for the full
        document. Yet, the docs don’t clarify if it’s possible to share
        information between components on different roots. 7. The new docs still
        recommend the godawful Create React App. Create React App might be
        convenient and unopinionated, but it’s a pile of outdated trash that
        never fixes important bugs. Just take a look at the Github issues if you
        don’t believe me. Luckily, the docs also recommend popular alternatives
        like Vite and Parcel. 8. Next.js is recommended for production-ready
        projects. No surprises here. Next.js is good, especially now that it
        allows more flexibility on page layouts. Other recommendations are
        Gatsby, Remix, and Razzle. 9. There’s a fine list of tool
        recommendations to build your own toolchain. If you want to fly solo,
        the docs suggest a sensible and trendy list of options. A pleasing
        surprise to me was that there’s no mention of Lerna, which makes sense
        after recent debacles. Instead, the recommendations for monorepos are Nx
        and Turborepo. 10. The recommended editors are VS Code, WebStorm,
        Sublime Text, and Vim. No big surprises there. I would only add that any
        JetBrains editor is up to the task, not only WebStorm. Also, Sublime
        Text is a meme, Dan Abramov only mentioned it because he still feels bad
        about that time on a conference talk when the Sublime Text pop-up for
        buying a licence appeared on screen.
      </h2>
    </div>
  );
}

export default BlogText;
