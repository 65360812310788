import React from "react";
import Brands from "../components/View/Brands";
import OurClients from "../components/View/OurClients";
import StartYourWebsite from "../components/View/StartYourWebsite";
import Layout from "../layout/Layout";

function Clients() {
  return (
    <div className="clients_container">
      <Layout>
        <div className="waveImageStyle">
          <OurClients />
          <Brands />
          {/* <StartYourWebsite /> */}
        </div>
      </Layout>
    </div>
  );
}

export default Clients;
