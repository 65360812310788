import { Button, Dropdown, Menu, Space } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
const menu = (history) => (
  <Menu
    className="dropDown_container"
    items={[
      {
        key: "1",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            // history={history}
            onClick={() => history.push("/workflow/Blockchain")}
          >
            Blockchain
          </a>
        ),
      },
      {
        key: "3",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history.push("/workflow/Web_Development")}
          >
            Web Development
          </a>
        ),
      },
      {
        key: "4",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history.push("/workflow/Mobile_Development")}
          >
            Mobile Development
          </a>
        ),
      },
      {
        key: "5",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history.push("/workflow/UI_UX_Design")}
          >
            UI/UX Design
          </a>
        ),
      },
      {
        key: "6",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history.push("/workflow/Graphic_Design")}
          >
            Graphic Design
          </a>
        ),
      },
      {
        key: "7",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => history.push("/workflow/SEO_&_Marketing")}
          >
            SEO & Marketing
          </a>
        ),
      },
    ]}
  />
);
const ServicesDropDown = ({ history }) => {
  var history = useHistory();
  const location = useLocation();
  const [path, setPath] = useState();
  const Blockchain = location.pathname === "/blockchain";

  const styleChangeHandler = () => {
    return location.pathname === "/our-services"
      ? setPath("textStyle")
      : location.pathname === "/workflow/Blockchain"
      ? setPath("textStyle")
      : location.pathname === "/workflow/Web_Development"
      ? setPath("textStyle")
      : location.pathname === "/workflow/Mobile_Development"
      ? setPath("textStyle")
      : location.pathname === "/UI_UX_Design"
      ? setPath("textStyle")
      : location.pathname === "/Graphic_Design"
      ? setPath("textStyle")
      : location.pathname === "/SEO_&_Marketing"
      ? setPath("textStyle")
      : null;
  };

  useEffect(() => {
    styleChangeHandler();
  }, [path]);

  console.log("location.pathname", location.pathname);

  return (
    <>
      <Space direction="vertical">
        <Space wrap>
          <Dropdown overlay={menu(history)} placement="bottom">
            <h2
              onClick={() => history.push("/our-services")}
              style={{ color: Blockchain ? "var(--textColor)" : null }}
            >
              <span className={path}>OUR SERVICES</span>
            </h2>
          </Dropdown>
        </Space>
      </Space>
    </>
  );
};

export default ServicesDropDown;
