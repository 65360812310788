import React from "react";
import TechAppCard from "../Cards/TechAppCard";
import bulb from "../../assets/bulb.png";
import setting from "../../assets/setting.png";
import board from "../../assets/board.png";
import computer from "../../assets/computer.png";
import { Col, Row } from "antd";

function Tools() {
  return (
    <div className="parent_container">
      <div className="techAppCard_container">
        <Row gutter={[20, 20]}>
          <Col xl={6} md={12} className="techAppCard_child">
            <TechAppCard
              img={bulb}
              heading="Creative Ideas"
              pera="Share your business idea. We will market your business to the audience which could generate as many leads and recognition as your well-established competitor"
            />
          </Col>
          <Col xl={6} md={12} className="techAppCard_child">
            <TechAppCard
              img={setting}
              heading="Innovative Tools"
              pera="We build innovative applications. We also develop hybrid applications using React Native and Flutter which are the most popular mobile application development frameworks."
            />
          </Col>
          <Col xl={6} md={12} className="techAppCard_child">
            <TechAppCard
              img={board}
              heading="Performance Optimized"
              pera="By ‘optimized’, we mean that your website should look amazing on smaller screens. Its load fast and be easy to navigate.your site should look fantastic on desktop screen too."
            />
          </Col>
          <Col xl={6} md={12} className="techAppCard_child">
            <TechAppCard
              img={computer}
              heading="Goal Achievement"
              pera="Goal achievement is our ability to identify future that we want and then to priorities our time, energy and focus to create a plan and path that leads to achieving those goals."
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Tools;
