import React from "react";
import AboutAnalysis from "../components/View/AboutAnalysis";
import AboutGoals from "../components/View/AboutGoals";
import AboutUs from "../components/View/AboutUs";
import ModernSolutions from "../components/View/ModernSolutions";
import StartYourWebsite from "../components/View/StartYourWebsite";
import WhoWeAre from "../components/View/WhoWeAre";
import Layout from "../layout/Layout";
function About({ location }) {
  return (
    <Layout>
      <div className="waveImageStyle">
        <AboutUs
          about={true}
          text="About Us"
          para="Here at Decotechs, our mission is to deliver exceptional value to our clients through our expertise in web and mobile application development. Our team is dedicated to creating amazing user experiences and fostering open communication to ensure that each project is completed to the highest standards. We believe in delivering not just a product, but a complete experience that exceeds our clients' expectations"
        />
        <AboutAnalysis />
        <WhoWeAre
          location="contactus"
          about={true}
          text={
            <h1>
              Who <span className="textStyle">We Are</span>
            </h1>
          }
        />
        <AboutGoals />
        <ModernSolutions location="contactus" />
        {/* <StartYourWebsite /> */}
      </div>
    </Layout>
  );
}

export default About;
