import { Button, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Blockchain from "../../assets/bitcoin.svg";
function BlockchainHeader() {
  const history = useHistory()
  return (
    <div
      className="parent_container"
      // style={{ backgroundColor: "#e4fff1" map}}
    >
      <div className="about_container">
        <Row align="middle">
          <Col xl={12} lg={12} md={24} sm={24} xs={24} className="mobileApp">
            <h1>Blockchain</h1>
            <p>
              Blockchain is modern day technology that can be used and
              configured for many applications and systems. The introduction of
              blockchain disrupted the traditional and old school business
              processes. We provide blockchain enabled services in a diverse
              zone such as MobileApps, WebApps / dApps, Maintenance.
            </p>
            <button onClick={() => history.push('contactus')} className="btn">GET IN TOUCH</button>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <img src={Blockchain} alt="image" width={"100%"} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BlockchainHeader;
