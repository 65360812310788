import { Col, Row } from "antd";
import React from "react";
import PlansCards from "../Cards/PlansCards";
import Gaming from "../../assets/gaming.svg";
import WorkProgress from "../../assets/WorkProgress.svg";

function WhoWeAre({ text, about, location }) {
  return (
    <div className="parent_container">
      <div className="appDevelopment_container">
        <Row align="middle">
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <img
              src={about ? Gaming : WorkProgress}
              alt="image"
              width={"100%"}
              className="img"
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <PlansCards
              location={location}
              heading={text}
              pera="Our achievement lies in the success of our client's business that’s why we make sure to build a never ending relationship with our client with a seamless communication gap. We don't just talk about honesty we prove it through our promising attitude and commendable efforts."
              btnText="CONTACT US"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default WhoWeAre;
