import React from "react";
import BlockchainTemplates from "../Cards/BlockchainTemplates";
import templatepaper from "../../assets/templatepaper.svg";
import pancel from "../../assets/pancel.svg";
import bitcoin from "../../assets/cubix-chain-crypto-currency.svg";
import doller from "../../assets/doller.svg";
import publicSvg from "../../assets/public.svg";
import PublicBlockchain from "../../assets/PublicBlockchain.svg";
import PrivateBlockchain from "../../assets/PrivateBlockchain.svg";
import expertize from "../../assets/expertize.svg";

function BlockchainMainTemplate() {
  return (
    <>
      <BlockchainTemplates
        position={true}
        img={publicSvg}
        icon={templatepaper}
        title="Public Blockchain"
        para="If one desires to create a completely open blockchain, which enables anyone and everyone to join and contribute to the network, they can go for a public blockchain. In a public blockchain, anyone is free to join and participate in the core activities of the blockchain network. Anyone can read, write, and audit the ongoing activities on the public blockchain network, which helps a public blockchain maintain its self-governed nature."
      />
      <BlockchainTemplates
        position={false}
        img={expertize}
        icon={pancel}
        title="Other Peer-to-Peer"
        para="A peer-to-peer service is a decentralized platform whereby two individuals interact directly with each other, without intermediation by a third party. The peer-to-peer architecture of blockchain allows all cryptocurrencies to be transferred worldwide, without the need of any intermediaries."
      />
      <BlockchainTemplates
        position={true}
        img={PrivateBlockchain}
        icon={bitcoin}
        title="Private Blockchain"
        para="If one needs to run a private blockchain that allows only selected entry of verified participants, like those for a private business, one can opt for a private blockchain implementation. A participant can join such a private network only through an authentic and verified invitation"
      />
      {/* <BlockchainTemplates
        position={false}
        img={PublicBlockchain}
        icon={doller}
        title="We have deep expertise in"
        para="Blockchain is modern day technology that can be used and configured for many applications and systems. The introduction of blockchain disrupted the traditional and old school business processes. We provide expert blockchain services in"
      /> */}
    </>
  );
}

export default BlockchainMainTemplate;
