import { Button, Col, Row } from "antd";
import React from "react";
import WorkProgress from "../../assets/WorkProgress.svg";
import Gaming from "../../assets/gaming.svg";
import graphicsDesigning from "../../assets/graphicsDesigning.svg";

function AboutUs({ text, about, para }) {
  return (
    <div
      className="parent_container"
      // style={{ backgroundColor: "#e4fff1" map}}
    >
      <div className="about_container">
        <Row align="middle">
          <Col xl={12} lg={12} md={24} sm={24} xs={24} className="mobileApp">
            <h1>{text}</h1>
            <p>{para}</p>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <img
              src={
                about === "workflow"
                  ? graphicsDesigning
                  : about
                  ? WorkProgress
                  : Gaming
              }
              alt="image"
              width={"100%"}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutUs;
