import React from "react";

function WorkflowHeading() {
  return (
    <div className="parent_container">
      <div className="workflow_heading">
        <h1>Our Workflow</h1>
        <p>
          We work with transparency, we follow the iterative module of
          development. Each iteration helps us to understand the requirements of
          our clients explicitly.
        </p>
      </div>
    </div>
  );
}

export default WorkflowHeading;
