// import { Row, Col } from "antd";
import ITFirms from "../assets/ITFirms.png";
import TopBadge from "../assets/Top-Badge.png";
import AppFutura from "../assets/App-Futura-Badge.png";
import Clutch from "../assets/Clutch-Badge.png";
import GoodFirms from "../assets/Good-Firms.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import GMB from "../assets/GMB.png";
import Stars from "../assets/Stars.png";
const BannerComponent = ({Heading, mainHeading, Span, Extras, Image}) => {
  return (
    <div className="main-banner-container">
      <div className="width75">
        {/* <Row> */}
        {/* <Col xs={24} md={24} lg={12}> */}
        <div className="heading">
          <h1>{Heading}</h1>
          <h3>
            {mainHeading}{" "}
            <span className="strong-underline">
              {Span}
            </span>{" "}
            {Extras}
          </h3>
          {/* <div className="consultation-call">
            <div className="icon">
              <BsFillTelephoneFill />
            </div>
            <h6>Schedule a Free Consultation Call</h6>
          </div> */}
        </div>
        <div className="image">
          <img src={Image} />
        </div>
      </div>
      <div className="reviews-count">
        <div className="reviews-head">
          <div className="gmb">
            <img src={GMB} />
            <h3>Google My Business</h3>
            <h6>5 Excellent | 52+ Reviews</h6>
          </div>
          <div className="stars">
            <img src={Stars} />
          </div>
        </div>
        <div className="ratings">
          <img src={ITFirms} />
          <img src={TopBadge} />
          <img src={AppFutura} />
          <img src={Clutch} />
          <img src={GoodFirms} />
        </div>
      </div>
    </div>
  );
};
export default BannerComponent;
