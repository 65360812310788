import { Row, Col, Button } from 'antd'
import Image1 from '../assets/clutch.png';
import { useHistory } from "react-router-dom";


const Banner = () => {
    const history = useHistory();

    return (
        <div className="banner">
            <div className='width75'>
                <Row gutter={[20, 20]}>
                    <Col xs={20} md={18} lg={14} xl={18}>
                        <div className='banner-content'>
                            <h1>Tackle Your Tech Skills Gap in the Shortest Time Possible</h1>
                            <p>Get top tech talent with Decotechs ‒ we provide team augmentation services to strengthen your project with the required skill sets ‒ fast and effortlessly.</p>
                        </div>
                    </Col>
                    <Col xs={0} md={0} lg={10} xl={6}>
                        <div className='bannerImage'>
                            <img src={Image1} alt='' />
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className='banner-btn'>
                            <Button onClick={() => history.push("/contactus")}>Contact Us</Button>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className='features'>
                <div className='features-cards black-color'>
                    <p>TEAM AUGMENTATION</p>
                </div>
                <div className='features-cards'>
                    <p>SAAS DEVELOPMENT</p>
                </div>
                <div className='features-cards'>
                    <p>MPV DEVELOPMENT</p>
                </div>
                <div className='features-cards'>
                    <p>PRODUCT DEVELOPMENT</p>
                </div>
                <div className='features-cards'>
                    <p>DEDICATED TEAM</p>
                </div>
                <div className='features-cards'>
                    <p>DIGITAL TRANSFORMATION</p>
                </div>
                <div className='features-cards'>
                    <p>MOBILE APP DEVELOPMENT</p>
                </div>
                <div className='features-cards'>
                    <p>WEB APP DEVELOPMENT</p>
                </div>
                <div className='features-cards'>
                    <p>DEVOPS SERVICES</p>
                </div>
            </div> */}

        </div>
    )
}

export default Banner;