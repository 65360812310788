import { Row, Col, Button } from 'antd'
import PortfolioCards from '../components/PortfolioCards';
import Layout from '../layout/Layout';
import PakGo from '../assets/pakgo.png';
import Ecommerce from '../assets/ecommerce.png';
import Screen from '../assets/screen.png';
import Inbox from '../assets/inbox.png';
import Quick from '../assets/quick.png';
import Mojo from '../assets/mojo.png';
import Right from '../assets/right.png';
import Food from '../assets/food.png';
import Nabein from '../assets/nabein.png';
import Athletix from '../assets/athletix.png';
import Jogo from '../assets/jogo.png';
import Penny from '../assets/penny.png';
const Portfolio = () => {
    const data = [
        {
            image: PakGo,
            brand: "Pak Go",
            text: "A complete logistics solution for Marine Group of companies for management of shipments via truck and train. Consumer booking app for tracking shipments. Driver app to pickup and dropoff shipments with respect to cargo. Carrier Management app for assigning bookings to drivers."
        },
        {
            image: Ecommerce,
            brand: "E-Commerce",
            text: "A multi-tenant ecommerce SaaS platform to serve all the needs of a modern shopping platform. Easy & rapidly configurable on client needs. Developed on cutting edge technologies."
        },
        {
            image: Screen,
            brand: "ScreenDiary",
            text: "Easily track, log & manage projects with ScreenDiary. Keeping you productive in a world of distraction by Effectively Managing in this modern era."
        },
        {
            image: Inbox,
            brand: "Inbox.Rent",
            text: "InboxRent is an web application where Landlords monitor tenants also manage the apartment booking & finance tracking via application. You can Advertise your property with Inbox.Rent and other major sites for free! Save Time, Grow Your Rental Portfolio."
        },
        {
            image: Quick,
            brand: "Quick Delivery",
            text: "QuickDelivery is an online food delivery marketplace that connects restaurants to the public, enabling foodies to digitally place orders to their favorite restaurants."
        },
        {
            image: Mojo,
            brand: "Mojo",
            text: "MoJo is a full-feature companion app for connectivity to venues, transit, travel, rewards, retail, entertainment, sports and journey management tools. Mojo also integrates over 3000 international transit agencies including trains and buses as well as ride shares and bike shares."
        },
        {
            image: Right,
            brand: "Right Transport User",
            text: "Right transport is a three in one app for booking rides within city, Bus tickets booking and 2D tickets booking. Our marketplace of services and listings help move people, efficiently, saving time and money."
        },
        {
            image: Food,
            brand: "FoodChoo",
            text: "FoodChoo is an online innovative on demand food delivery platform aims to provide best experience from their client first mobile app made on state of the art technologies."
        },
        {
            image: Nabein,
            brand: "Nabeinn Foods",
            text: "nabeinn foods is all about homemade food. With nabeinn foods, you can order delicious meals from locals in your neighbourhood. Simply use the app to browse local meals and get all the details you want. Order the food you like, conveniently pay on the app and choose pick up or delivery."
        },
        {
            image: Athletix,
            brand: "AthletiX Training",
            text: "A comprehensive training system with an emphasis on proper biomechanical movements and neurological conditioning to enhance athletic ability by increase speed, power and proprioception. By developing joint and tendon resilience you become more resistant to injury.. Join AthletiX for your own customized athletic training program to follow step by step."
        },
        {
            image: Jogo,
            brand: "Jogo",
            text: "JOGO aims to revolutionize the world of football and help you reach your full potential. By bringing data science, AI and machine learning (ML) together with tailor-made exercises and assignments."
        },
        {
            image: Penny,
            brand: "Penny Pinch",
            text: "A convenient, easy, and rewarding way to shop and send money. Save money on your purchases through discounts while also benefiting from an impressive loyalty program. Also, send and receive money within seconds to anyone who has a Penny Pinch account."
        },

    ]
    return (
        <Layout>
            <div className="portfolio-container">
                <div className="head">
                    <h2>Our Portfolio</h2>
                </div>
                <div className='domains'>
                    <Row gutter={[20, 20]}>
                        {
                            data.map((item, i) => {
                                return (
                                    <Col key={i} xs={24} md={12} lg={8} xl={6}>
                                        <PortfolioCards image={item.image} brand={item.brand} text={item.text} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                <div className='connect'>
                    <h3>Let’s work together on your next project</h3>
                    <p>Well-trained, highly experienced and skilled professionals are always available to help you out in your dream projects.</p>
                    <Button>Let{"'"}s Talk</Button>
                </div>
            </div>
        </Layout>
    )
}

export default Portfolio;