import Testimonial from "../components/Testimonial";
import { Row, Col } from 'antd'
import Layout from "../layout/Layout";
const ClientTestimonial = () => {
    const Data = [
        {
            text1: `${"❝"}I am extremely satisfied with the services provided by Decotechs. Their team was professional, knowledgeable, and always available to answer my questions. They delivered my project on time and within my budget`,
            text2: `I highly recommend Decotechs to anyone looking for a reliable and efficient technology partner.${'❞'}`,
            client: "John D"
        },
        {
            text1: `${"❝"}Decotechs has been an excellent partner in our company's digital transformation journey. Their team has helped us develop and implement customized software solutions that have improved our productivity and efficiency.`,
            text2: `${'❞'}Their technical expertise and commitment to customer service are unparalleled.`,
            client: 'Sarah M'
        },
        {
            text1: `${"❝"}I cannot thank Decotechs enough for their outstanding service. They helped me develop a website for my business that exceeded my expectations. Their team was patient, collaborative, and attentive to my needs. They went above and beyond to ensure that my website was user-friendly and visually appealing`,
            text2: `${'❞'} I highly recommend Decotechs to anyone looking for a reliable and innovative technology partner.`,
            client: 'Mark T'
        },
        {
            text1: `${"❝"}Decotechs engaged us with skilful developer & engineer. The project originally was supposedly should have taken months to complete but he finished it in short amount of time.`,
            text2: `${'❞'}I${"'"}m very impressed by the outdoor of the project and wealth of knowledge there developers have In react application.`,
            client: 'Sofia Papadopoulos'
        },
        {
            text1: `${"❝"}The team at this company is amazing!
            They are friendly, knowledgeable, and
            always willing to help. They provide topnotch customer service and are a
            pleasure to work with.`,
            text2: `${'❞'}I highly
            recommend them to anyone looking for
            a great experience`,
            client: 'Emily Johnson'
        },
        {
            text1: `${"❝"}Decotechs has helped us transform our
            business with their innovative
            technology solutions. Their team has
            provided us with the expertise and
            support we need to stay ahead of the
            curve.`,
            text2: `${'❞'}We highly recommend their
            services to anyone looking to take their
            business to the next level`,
            client: 'Daniel Kim'
        },
        {
            text1: `${"❝"}Decotechs has helped us transform our
            business with their innovative
            technology solutions. Their team has
            provided us with the expertise and
            support we need to stay ahead of the
            curve.`,
            text2: `${'❞'}We highly recommend their
            services to anyone looking to take their
            business to the next level`,
            client: 'Daniel Kim'
        },
        {
            text1: `${"❝"}I'
            ve used several services like this before,
            but none of them compare to the level
            of professionalism and expertise that
            this company provides.`,
            text2: `${'❞'}I highly
            recommend them.
            `,
            client: 'Maria Rossi'
        },
        {
            text1: `${"❝"}I had an amazing experience with this
            service. The staff was friendly,
            knowledgeable, and went out of their
            way to ensure my satisfaction`,
            text2: " ",
            client: 'Pierre Dupont'
        },
        {
            text1: `${"❝"}We have been working with Decotechs
            for several years now and have been
            consistently impressed with their level of
            expertise and professionalism.`,
            text2: `${'❞'}Their
            team has helped us achieve great
            results and we couldn
            't be happier with
            their services.`,
            client: 'Laura Martinez'
        },
    ]
    return (
        <Layout>

            <div className="client-testimonial">
                <div className="banner">
                    <p>Welcome to Decotechs{"'"} dedicated testimonials page! We are thrilled to share feedback and reviews from our satisfied clients who have had a positive experience working with us. As a company, we are committed to delivering the highest quality services and products, and our client{"'"}s feedback is a testament to our efforts.</p>
                    <p>Here are some of the testimonials we have received from our clients:</p>
                </div>
                <div className="width90">
                    <Row gutter={[40, 60]}>
                        {
                            Data.map((item, i) => {
                                return (
                                    <Col key={i} xs={24} md={12} lg={8} xl={8}>
                                        <Testimonial item={item} />
                                    </Col>

                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default ClientTestimonial;