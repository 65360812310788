import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function PlansCards({ heading, pera, btnText, location }) {
  const history = useHistory();
  return (
    <div className="plansCards_conatiner">
      {heading}
      <p>{pera}</p>
      <Button
        onClick={() => history.push(`/${location}`)}
        size="large"
        shape="round"
        style={{
          fontFamily: "Muli",
          fontSize: "12px",
          backgroundColor: "var(--primary)",
          color: "white",
        }}
      >
        {btnText}
      </Button>
    </div>
  );
}

export default PlansCards;
