import React from "react";

function BlockchainCard({ img, title, para }) {
  return (
    <div className="BlockchainCard">
      <img src={img} alt="blockchain" />
      <h1>{title}</h1>
      <p>{para}</p>
    </div>
  );
}

export default BlockchainCard;
