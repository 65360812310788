import { Col, Row } from "antd";
import React from "react";
import RoundProgress from "../Charts/RoundProgress";

function AboutGoals() {
  return (
    <div className="parent_container">
      <div style={{ width: "90%" }}>
        <Row justify="center" gutter={30}>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <RoundProgress
              num="1"
              percentage="25"
              color="var(--primary)"
              text="Set Your Goals"
              para="We believe at work with the best quality and best service. We know that customer’s growth is our growth, we always help our customers to fulfill their goals"
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <RoundProgress
              num="2"
              percentage="50"
              color="var(--secondary)"
              text="Get Our Expertise"
              para="We satisfy our customers by our best services and want to know in this IT industry as the reliable and user- friendly software service provider."
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <RoundProgress
              num="3"
              percentage="75"
              color="var(--primary)"
              text="See Results"
              para="We want to become leading performer in providing the best quality solutions, and we want to grow as the best IT service provider in this marketplace"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutGoals;
