import { Col, Row } from "antd";
import React from "react";
import TeamCard from "../components/Cards/TeamCard";
import Layout from "../layout/Layout";
import {
  developer,
  BusinessDeveloper,
  Graphic,
  BlockChainDeveloper,
  HumanResource,
} from "../assets/TeamData";

function Team() {
  return (
    <Layout>
      <div className="parent_container team_container">
        <div className="appDevelopment_container">
          <Row gutter={[30, 30]}>
            <Col xs={24}>
              <h1 className="heading">
                Business <span className="textStyle">Developer</span>
              </h1>
            </Col>
            {BusinessDeveloper.map((data) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <TeamCard
                  image={data.image}
                  name={data.name}
                  para={data.designation}
                />
              </Col>
            ))}
            <Col xs={24}>
              <h1 className="heading">
                <span className="textStyle">Developers</span>
              </h1>
            </Col>
            {developer.map((data) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <TeamCard
                  image={data.image}
                  name={data.name}
                  para={data.designation}
                />
              </Col>
            ))}
            <Col xs={24}>
              <h1 className="heading">
                <span className="textStyle">Graphics</span>
              </h1>
            </Col>
            {Graphic.map((data) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <TeamCard
                  image={data.image}
                  name={data.name}
                  para={data.designation}
                />
              </Col>
            ))}
            <Col xs={24}>
              <h1 className="heading">
                <span className="textStyle">BlockChain</span> Developers
              </h1>
            </Col>
            {BlockChainDeveloper.map((data) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <TeamCard
                  image={data.image}
                  name={data.name}
                  para={data.designation}
                />
              </Col>
            ))}
            <Col xs={24}>
              <h1 className="heading">
                Human<span className="textStyle">Resource</span>
              </h1>
            </Col>
            {HumanResource.map((data) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <TeamCard
                  image={data.image}
                  name={data.name}
                  para={data.designation}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  );
}

export default Team;
