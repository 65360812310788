import React from "react";
import AboutUs from "../components/View/AboutUs";
import DataCards from "../components/View/DataCards";
import ModernClean from "../components/View/ModernClean";
import WhoWeAre from "../components/View/WhoWeAre";
import Layout from "../layout/Layout";
import ServicesNew from "./Services";

function Services() {
  return (
    <div className="Services_Container">
      <Layout>
        <div className="waveImageStyle">
          <AboutUs
            text="Our Services"
            para="Decotechs is an advanced digital company that works on the next
          level and takes its clients to a profitable journey. We work with
              complete dedication, having determination, bringing innovation,
              maintaining professionalism, and placing efforts. You can
              productively grow your online visibility by having our multiple
              problem-solving services.
            "
          />
          <DataCards />
          <WhoWeAre
            location='contactus'
            text={
              <h1>
                Start <span className="textStyle">Working</span> With Us
              </h1>
            }
          />
          <ModernClean />
          {/* <StartYourWebsite /> */}
        </div>
        <ServicesNew />
      </Layout>
    </div>
  );
}

export default Services;
