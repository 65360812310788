import React from "react";
import BlogGridText from "../components/View/BlogGridText";
import GridCards from "../components/View/GridCards";
import LoadMore from "../components/View/LoadMore";
import Layout from "../layout/Layout";

function BlogGrid() {
  return (
    <div className="blogGrid_container">
      <Layout>
        <BlogGridText />
        <GridCards />
        <LoadMore />
      </Layout>
    </div>
  );
}

export default BlogGrid;
