import React from "react";
import { Progress } from "antd";

function ProgressChart({ color, parentage, text }) {
  return (
    <div className="ProgressChart">
      <div>
        <p>{text}</p>
        <p>{parentage}%</p>
      </div>
      <Progress strokeLinecap="butt" strokeColor={color} percent={parentage} showInfo={false} />
    </div>
  );
}

export default ProgressChart;
