import { Col, Row } from "antd";
import React from "react";
import doller from "../../assets/doller.svg";
import PublicBlockchain from "../../assets/PublicBlockchain.svg";

function Expertise() {
  return (
    <div className="parent_container">
      <div className="BlockchainTemplates">
        <Row align="middle" justify="space-between">
          <Col lg={12} xs={24}>
            <img src={PublicBlockchain} alt="Blockchain" />
          </Col>
          <Col lg={12} xs={24}>
            <div className="template_div">
              <img src={doller} alt="paper" width={80} />
              <h1>We have deep expertise in</h1>
              <p>
                Blockchain is modern day technology that can be used and
                configured for many applications and systems. The introduction
                of blockchain disrupted the traditional and old school business
                processes. We provide expert blockchain services in
              </p>
              <div className="list_style">
                <div>
                  <li>Initial Coin Offerings (ICOs)</li>
                  <li>Multi Level Marketing (MLM)</li>
                  <li>Return on Investment (ROI)</li>
                  <li>NFT Gaming</li>
                </div>
                <div>
                  <li>Blockchain Gaming</li>
                  <li>Uniswap</li>
                  <li>Sushiswap</li>
                  <li>Panckaeswap</li>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Expertise;
