import GameDevelopment from '../../assets/Game-Development (1).png';
const SolutionsCards = ({Image, Title, Details}) => {
    return(
        <div className="solution-card">
            <img src={Image} />
            <h3>{Title}</h3>
            <p>{Details}</p>
        </div>
    )
}

export default SolutionsCards;