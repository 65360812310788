import React from "react";

function TeamCard({ name, para, image }) {
  return (
    <>
      <div className="TeamCard_container">
        <img src={image} alt="team" width={120} />
        <h1>{name}</h1>
        <p>{para}</p>
      </div>
    </>
  );
}

export default TeamCard;
