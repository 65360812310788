import React from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

function PortfolioText({detail , link, title}) {
  return (
    <div className="PortfolioText">
      {/* <h1>Details & Info</h1> */}
      {/* <h1>{title}</h1> */}
      <a href={link} target={'_blank'}>{title}</a>
      <p>
        {detail}
        {/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
        volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
        Duis autem vel. */}
      </p>
      <div>
        <div className="category">
          <h2>Category</h2>
          <li>Photography</li>
        </div>
        <div className="category">
          <h2>Client</h2>
          <li>Themeforest</li>
        </div>
        <div className="social">
          <h2>Share:</h2>
          <div>
            <FaFacebookF size={16} color='var(--text)'/>
            <AiOutlineTwitter size={16} color='var(--text)'/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioText;
