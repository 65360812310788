import { Layout, Switch } from "antd";

import { MdOutlineDarkMode, MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { toggleTheme } from "../redux";
import MenuDrawer from "../components/Drawer/HeaderDrawer";
import Footer from "./Footer";
import Logo from "../assets/blueLogo.png";
import { useHistory, useLocation } from "react-router-dom";

const { Content } = Layout;

const MobileLayout = ({ active, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const Blockchain = location.pathname === "/blockchain";

  return (
    <Layout className="m-layout">
      <div
        className="mobile-header"
        style={{
          position: Blockchain ? "relative" : null,
          background: Blockchain ? "transparent" : null,
        }}
      >
        <h1 onClick={() => history.push("/")}>
          <img src={Logo} alt="LOGO" width={150} />
        </h1>
        <MenuDrawer />
      </div>
      <Content className="m-children">{children}</Content>
      <Footer />
    </Layout>
  );
};

export default MobileLayout;
