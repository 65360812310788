import { Col, Row } from "antd";
import React from "react";
import PlansCards from "../Cards/PlansCards";
import Statistic from "./Statistic";

function ModernSolutions({location}) {
  return (
    <div className="parent_container">
      <div className="appDevelopment_container">
        <Row align="middle">
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <PlansCards
            location={location}
              heading={
                <h1>
                  Modern <span className="textStyle">Solutions</span>
                </h1>
              }
              pera="We are providing complete web app solutions, we develop scalable, faster and responsive Web Applications using the most popular technologies. We build both single page and multipage applications and websites using the latest technologies like ReactJS, NodeJS, ExpressJS, MongoDB and many more."
              btnText="CONTACT US"
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Statistic />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ModernSolutions;
