import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import About from "../pages/AboutUs";
import Dashboard from "../pages/Dashboard";

import Login from "../pages/Login";
import Clients from "../pages/Clients";
import ServicesOld from "../pages/Services_old";
import TechAndApp from "../pages/TechAndApp";
import BlogGrid from "../pages/BlogGrid";
import ContectUs from "../pages/ContectUs";
import Custom404 from "../pages/Custom404";
import Team from "../pages/Team";
import PortfolioOld from "../pages/Portfolio_old";
import PortfolioSummery from "../pages/PortfolioSummery";
import Blog from "../pages/Blog"
import BlockchainOld from "../pages/BlockchainOld";
import Workflow from "../pages/Workflow";
import ClientTestimonial from "../pages/ClientTestimonial";
import Portfolio from '../pages/Portfolio';
import Services from '../pages/Services';
import CaseStudy from "../pages/CaseStudy";
import BlockChain from "../pages/Blockchain";
import MobileApp from "../pages/MobileApp";
import BlockChainGameDevelopment from "../pages/BlockChainGameDevelopment";
import DigitalTransformation from "../pages/DigitalTransformation";
import DataAnalytics from "../pages/DataAnalytics";
import { DiGoogleCloudPlatform } from "react-icons/di";
const Routes = () => {

  window.Tawk_API = window.Tawk_API || {}
  window.Tawk_LoadStart = new Date();

  useEffect(() => {

    // tawkToLoadScripts()

    const attritbutesToSet = {
      user: 'Ahmed Hassan',
      college: 'Demo college',
      gradYear: '2020',
    }

    window.Tawk_API.onLoad = function () {
      window.Tawk_API.setAttributes(
        {
          ...attritbutesToSet
        },
        function (error) {
          console.log("window.Tawk_API errorr", error)
        }
      );
    };

    window.Tawk_API.onChatMaximized = function () {

      const page_path = window.location.pathname + window.location.search;
      window.Tawk_API.addEvent('current-path', {
        'path': page_path,
      }, function (error) {
        console.log("window.Tawk_API errorr", error)
      });
    }

  }, [])

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {/* <Route path="/login" exact component={Login} /> */}
          {/* <Route path="/" exact component={Dashboard} /> */}
          <Route path="/" exact component={TechAndApp} />
          <Route path="/team" exact component={Team} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/workflow/:id" exact component={Workflow} />
          <Route path="/blockchain-old" exact component={BlockchainOld} />
          <Route path="/portfolio" exact component={PortfolioOld} />
          <Route path="/portfoliosummery" exact component={PortfolioSummery} />
          <Route path="/about" exact component={About} />
          <Route path="/services" exact component={ServicesOld} />
          <Route path="/clients" exact component={Clients} />
          <Route path="/bloggrid" exact component={BlogGrid} />
          <Route path="/contactus" exact component={ContectUs} />
          <Route path="/custom404" exact component={Custom404} />
          <Route path="/testimonial" exact component={ClientTestimonial} />
          <Route path="/our-portfolio" exact component={PortfolioOld} />
          <Route path="/our-portfolio" exact component={Portfolio} />
          {/* <Route path="/our-services" exact component={Services} /> */}
          <Route path="/our-services" exact component={ServicesOld} />
          <Route path="/case-studies" exact component={CaseStudy} />
          <Route path="/blockchain" exact component={BlockChain} />
          <Route path="/mobile-app-development" exact component={MobileApp} />
          <Route path="/blockchain-game-development" exact component={BlockChainGameDevelopment} />
          <Route path="/digital-transformation-consulting-services" exact component={DigitalTransformation} />
          <Route path="/data-analytics-consulting-services" exact component={DataAnalytics} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
