import React from "react";
import PortfolioImages from "../components/View/PortfolioImages";
import PortfolioText from "../components/View/PortfolioText";
import Layout from "../layout/Layout";
import { useLocation } from "react-router-dom";

function PortfolioSummery() {
  const location = useLocation();
  return (
    <Layout>
      <div className="parent_container waveImageStyle">
        <div className="PortfolioSummery">
          <PortfolioImages img={location?.state?.img} carouselImg={location?.state?.carouselImg}/>
          <PortfolioText detail={location?.state?.detail} title={location?.state?.title} link={location?.state?.link}/>
        </div>
      </div>
    </Layout>
  );
}

export default PortfolioSummery;
