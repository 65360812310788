// import React from "react";
// import webDesign from "../../assets/webDesign.jpg";
// import videoMarketing from "../../assets/video-marketing.jpg";
// import mobile from "../../assets/asd.jpg";

// function PortfolioImages() {
//   return (
//     <div className="PortfolioImages">
//       <img src={webDesign} alt="images" width={"100%"} />
//       <img src={videoMarketing} alt="images" width={"100%"} />
//       <img src={mobile} alt="images" width={"100%"} />
//     </div>
//   );
// }

// export default PortfolioImages;
import { Carousel } from "antd";
import React from "react";
import webDesign from "../../assets/jakobowens.jpg";
import videoMarketing from "../../assets/Simple-Mockup.png";
import mobile from "../../assets/asd.jpg";
// const contentStyle = {
//   height: "500px",
//   width: "100%",
//   color: "#fff",
//   lineHeight: "160px",
//   textAlign: "center",
//   //   background: "#364d79",
//   objectFit: "cover",
// };

const PortfolioImages = ({img, carouselImg}) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Carousel className="carousal_Style" autoplay afterChange={onChange}>
      {/* <h3 style={contentStyle}>1</h3> */}
      {/* <div>
        <img src={webDesign} alt="images" style={contentStyle} />
      </div> */}
      <div>
        {/* <h3 style={contentStyle}>2</h3> */}
        {/* <img src={videoMarketing} alt="images" className="carousal_Style" /> */}
        <img src={img} alt="images" className="carousal_Style" />
      </div>
      <div>
        {/* <h3 style={contentStyle}>3</h3> */}
        {/* <img src={mobile} alt="images" className="carousal_Style" /> */}
        <img src={carouselImg} alt="images" className="carousal_Style" />
      </div>
    </Carousel>
  );
};

export default PortfolioImages;
