import { Button, Dropdown, Menu, Space } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
const menu = (items) => (
  <Menu
    className="dropDown_container"
    items={items}
  />
);
const DropDown = ({ history,name,items }) => {
  var history = useHistory();
  const location = useLocation();
  const [path, setPath] = useState();
  const Blockchain = location.pathname === "/blockchain";

  // const styleChangeHandler = (name) => {
  //   return location.pathname === "/about"
  //     ? setPath(`textStyle-${name}`)
  //     : location.pathname === "/bloggrid"
  //     ? setPath(`textStyle-${name}`)
  //     : location.pathname === "/contectus"
  //     ? setPath(`textStyle-${name}`)
  //     : location.pathname === "/blockchain"
  //     ? setPath("blockchain_textStyle")
  //     : "";
  // };
  // useEffect(() => {
  //   styleChangeHandler();
  // }, [path]);
  return (
    <>
      <Space direction="vertical">
        <Space wrap>
          <Dropdown overlay={menu(items)} placement="bottom">
            <h2 style={{ color: Blockchain ? "var(--textColor)" : null }}>
              <span className={path} onClick={() =>name === 'Clients' && history.push("/clients")} >{name}</span>
            </h2>
          </Dropdown>
        </Space>
      </Space>
    </>
  );
};

export default DropDown;
