import { Col, Row } from "antd";
import React from "react";
import PlansCards from "../Cards/PlansCards";
import finance from "../../assets/finance.png";
import HandCoding from "../../assets/HandCoding.svg";
import WorkProgress from "../../assets/WorkProgress.svg";
import bitcoin from "../../assets/bitcoin.svg";

function AppDevelopment({ position, location }) {
  return (
    <>
      {position === "right" ? (
        <div className="parent_container">
          <div className="appDevelopment_container">
            <Row align="middle">
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PlansCards
                  location={location}
                  heading={
                    <h1>
                      App <span className="textStyle">Development</span>
                    </h1>
                  }
                  pera="We are providing highly polished native applications for Android and Cross-platform applications for Android and iOS. We build innovative applications for start-ups and enterprises. We also develop hybrid applications using React Native and Flutter which are the most popular mobile application development frameworks."
                  btnText="LEARN MORE"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <img
                  src={HandCoding}
                  alt="image"
                  className="img"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : position === "left" ? (
        <div className="parent_container">
          <div className="appDevelopment_container">
            <Row align="middle"
            // gutter={70}
            >
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <img
                  src={WorkProgress}
                  alt="image"
                  className="img"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PlansCards
                  location={location}
                  heading={
                    <h1>
                      <span className="textStyle">E-Commerce</span> Plan
                    </h1>
                  }
                  pera="Leading ecommerce development agency that helps you get a complete e-commerce website ready for you to start your online operation. We integrate revenue generation features while remaining completely able to customize as per your requirement."
                  btnText="LEARN MORE"
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="parent_container">
          <div className="appDevelopment_container">
            <Row align="middle"
            // gutter={70}
            >
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <PlansCards
                  location={location}
                  heading={
                    <h1>
                      <span className="textStyle">Blockchain</span>
                    </h1>
                  }
                  pera="Blockchain is modern day technology that can be used and configured for many applications and systems. The introduction of blockchain disrupted the traditional and old school business processes. We provide blockchain enabled services in a diverse zone such as MobileApps, WebApps / dApps, Maintenance."
                  btnText="LEARN MORE"
                />
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <img
                  src={bitcoin}
                  alt="image"
                  className="img"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default AppDevelopment;
