import { BsFillStarFill } from 'react-icons/bs'
const Testimonial = ({ item }) => {
    return (
        <div className="testimonial-container">
            <div className="stars">
                <BsFillStarFill style={{ marginTop: '75px' }} />
                <BsFillStarFill style={{ marginTop: '28px' }} />
                <BsFillStarFill />
                <BsFillStarFill style={{ marginTop: '28px' }} />
                <BsFillStarFill style={{ marginTop: '75px' }} />
            </div>
            <h2>Client Testimonial</h2>
            <div className='review'>
                <p>{item.text1}</p>
                <p>{item.text2}</p>
            </div>
            <h5>{item.client}</h5>
            <h6>@ Decotechs</h6>
        </div>
    )
}

export default Testimonial;