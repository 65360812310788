import { Row, Col } from 'antd'
const CaseStudyCard = ({ Data }) => {
    return (
            Data.map((item, i) => {
                return (
                    <div className='case-study-card' key={i}>

                        <Row  gutter={[{ xs: 10, md:10, lg:20, xl:20},{ xs: 10, md:10, lg:20, xl:20}]} >
                            <Col xs={24} md={24} lg={6} xl={6}>
                                <h3>Project</h3>
                            </Col>
                            <Col xs={24} md={24} lg={18} xl={18}>
                                <p>{item.project}</p>
                            </Col>
                            <Col xs={24} md={24} lg={6} xl={6}>
                                <h3>Challenge</h3>
                            </Col>
                            <Col xs={24} md={24} lg={18} xl={18}>
                                <p>{item.challenge}</p>
                            </Col>
                            <Col xs={24} md={24} lg={6} xl={6}>
                                <h3>Solution</h3>
                            </Col>
                            <Col xs={24} md={24} lg={18} xl={18}>
                                <p>{item.solutions}</p>
                            </Col>
                            <Col xs={24} md={24} lg={6} xl={6}>
                                <h3>Results</h3>
                            </Col>
                            <Col xs={24} md={24} lg={18} xl={18}>
                                <p>{item.results}</p>
                            </Col>
                        </Row>

                    </div>
                )
            })
    )
}

export default CaseStudyCard;