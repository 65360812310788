import React from "react";
import { useHistory } from "react-router-dom";

function PortfolioCard({ category, title, carouselImg, img, detail, link }) {
  const history = useHistory();
  return (
    <div
      className="PortfolioCard"
      // onClick={() => history.push("/portfoliosummery" , {detail : detail , img : img, carouselImg: carouselImg , title: title, link: link})}
    >
      <img src={img} alt="NFT" />
      <div className="text">
        <h1>{title}</h1>
        <p>{category}</p>
      </div>
    </div>
  );
}

export default PortfolioCard;
