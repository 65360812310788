import React from "react";
import { useHistory, useParams } from "react-router-dom";

function ServicesCard({ heading, img, para, param }) {
  console.log("heading:", param);
  const history = useHistory();
  return (
    <div
      className="ServicesCard"
      onClick={() => history.push(`/workflow/${param}`)}
    >
      {img}
      <h1>{heading}</h1>
      <p>{para}</p>
    </div>
  );
}

export default ServicesCard;
