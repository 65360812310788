import Layout from "../layout/Layout";
import BannerComponent from "../components/BannerComponent";
import { Row, Col } from "antd";
import SolutionsCards from "../components/Cards/SolutionsCards";
import FeaturesCards from "../components/Cards/FeaturesCards";
import TimeLine from "../components/TImeLine";
import TestimonialCards from "../components/Cards/TestimonialCards";
import FAQ from "../components/FAQ";
import Form from "../components/View/Form";
import Image1 from "../assets/Mobile-App-Development-Services.jpg";
import Image2 from "../assets/Native-App-Development.png";
import Image3 from "../assets/Cross-Platform-App-Development.png";
import Image4 from "../assets/Progressive-Web-Apps.png";
import Image5 from "../assets/Hybrid-App-Development.png";
import Image6 from "../assets/App-Prototyping.png";
import Image7 from "../assets/App-Testing.png";
import Image8 from "../assets/iOS-App-Development.png";
import Image9 from "../assets/Android-App-Development.png";
import Image10 from "../assets/Cross-Platform-App-Development.png";
import Image11 from "../assets/App-Maintenance-and-Support.png";
import Image12 from "../assets/David-Joseph.jpeg";
import Image13 from "../assets/Dane-Lee.jpeg";
import Image14 from "../assets/Stella-John.jpeg";
import Image15 from "../assets/Elijah.jpeg";
import Image16 from "../assets/Moses.jpeg";
import Image17 from "../assets/Moses.jpeg";

const MobileApp = () => {
  const SolutionData = [
    {
      Image: Image2,
      Title: "Native App Development",
      Details:
        "Native app development involves the creation of an app specifically designed for a particular mobile operating system, whether it is Android or iOS. This type of development is typically done using the official software development kit (SDK) for the operating system in question.",
    },
    {
      Image: Image3,
      Title: "Cross-Platform App Development",
      Details:
        "The process of creating cross-platform applications is an app that will work on multiple mobile platforms, such as Android and iOS. The app code is written once and then adapted to work on each platform.",
    },
    {
      Image: Image4,
      Title: "Progressive Web Apps (PWAs)",
      Details:
        "PWAs are web apps that are designed to look and feel like native apps. They are often faster, more reliable, and more secure than regular web apps, making them an increasingly popular choice for mobile app development.",
    },
    {
      Image: Image5,
      Title: "Hybrid App Development",
      Details:
        "Hybrid app development involves the use of a combination of web technologies and native code to create an app that is compatible with both Android and iOS. Most of the time, hybrid apps are easier and faster to make than native apps. making them an attractive option for many developers.",
    },
    {
      Image: Image6,
      Title: "App Prototyping",
      Details:
        "App prototyping is the process of creating a low-fidelity version of an app, usually as a way to quickly test out potential features and user flows. Prototyping can be done in various tools, such as Adobe XD or Sketch, and is a great way to get feedback on an app before it is fully developed.",
    },
    {
      Image: Image7,
      Title: "App Testing",
      Details:
        "App testing is the process of ensuring that an app is working correctly before it is launched. This can involve a variety of tests, such as usability testing, performance testing, and security testing. Testing is a very important part of making an app, and can help to ensure that the app works correctly on both Android and iOS.",
    },
  ];
  const FeaturesData = [
    {
      Image: Image8,
      Title: "iOS App Development",
      Link1: "",
      Details:
        "We have extensive experience in creating high-quality iOS apps for iPhone and iPad.",
      Link2: "",
    },
    {
      Image: Image9,
      Title: "Android App Development",
      Link1: "",
      Details:
        "We also specialize in developing top-notch android development apps for smartphones and tablets.",
      Link2: "",
    },
    {
      Image: Image10,
      Title: "Cross-platform App Development",
      Link1: "",
      Details:
        "Our team is well-versed in creating cross-platform apps that work seamlessly on multiple platforms.",
      Link2: "",
    },
    {
      Image: Image11,
      Title: "App Maintenance and Support",
      Link1: "",
      Details:
        "We offer ongoing maintenance and support to ensure that your app stays up-to-date and runs smoothly.",
      Link2: "",
    },
  ];
  const TimeLineData = [
    {
      Heading: "Conception and Strategy",
      Details: "The first step in the development stage of a mobile app is understanding your business's needs and goals. We work with you to develop a strategy for your app, ensuring it aligns with your overall business objectives.",
    },
    {
      Heading: "Design and UI/UX",
      Details: "Our team of designers will create a visually stunning and user-friendly design for your app. We focus on providing a great user experience UX and UI components to ensure your app is easy to use and navigate.",
    },
    {
      Heading: "Development and Testing",
      Details: "Our developers bring your app to life using the latest technologies and tools. We also conduct thorough mobile app testing to ensure that your app is bug-free and runs smoothly.",
    },
    {
      Heading: "Deployment and Maintenance",
      Details: "Once your app is complete, we will help you with the deployment process and provide ongoing maintenance and support to ensure your app continues to function at its best.",
    },
  ]
  const ReviewsData = [
    {
      Image: Image12,
      Name: "David Joseph",
      Review:
        "I had the pleasure of working with SDLC Corp for my company’s web app development needs. Their app development services were excellent, and they went above and beyond in creating a mobile app with excellent UX and UI design. I highly recommend them for anyone looking to develop a mobile app.",
    },
    {
      Image: Image13,
      Name: "Dane Lee",
      Review:
        "SDLC Corp was the perfect choice for our company’s mobile application development needs. They were able to develop both the iOS and Android versions of our app with a seamless user experience. Their team was responsive and professional throughout the project.",
    },
    {
      Image: Image14,
      Name: "Stella John",
      Review:
        "I was impressed with the project development solution provided by SDLC Corp for my mobile app. They were able to quickly understand my needs and develop a mobile solution that exceeded my expectations. Their cross-platform apps are available on both the iOS (apple app store) and Android app stores, and I highly recommend them as a mobile app development company.",
    },
    {
      Image: Image15,
      Name: "Elijah",
      Review:
        "Working with SDLC mobile app development company was a pleasure from start to finish. Their team was professional, responsive, and dedicated to delivering a high-quality product. We are thrilled with the app they created for us and highly recommended it to anyone looking for top-notch development services.",
    },
    {
      Image: Image16,
      Name: "Moses",
      Review:
        "From the beginning, SDLC mobile app development company impressed us with their deep understanding of our industry and user needs. They worked collaboratively with our team to refine our vision and create an app that exceeded our expectations. The final product is sleek, intuitive, and incredibly user-friendly. We couldn’t be happier with the outcome and highly recommend their services to anyone looking to develop a mobile app.",
    },
    {
      Image: Image17,
      Name: "Tanaya Bajaj",
      Review:
        "I am extremely satisfied with the mobile applications developed by SDLC Corp. Their mobile solution development approach is outstanding, and their team is highly skilled in creating cross-platform apps that are compatible with both iOS and Android. I would highly recommend SDLC Corp as a top mobile app development company for any business looking for a reliable and efficient mobile solution.",
    },
  ];
  const FaqData = [
    {
      Question: "What are the various types of apps?",
      Answer: "Based on the technology used to make them, there are 3 main types of mobile apps: native apps, hybrid apps, and web-based mobile apps like progressive web apps (PWA)."
    },
    {
      Question: "How much does it usually cost to develop a mobile app?",
      Answer: "The price of developing a mobile app depends a lot on things like the target market, the location of the developer, the app’s features, and the platform that was used to make it."
    },
    {
      Question: "What are the benefits of mobile app development for businesses?",
      Answer: "The Top 5 Benefits of Making a Mobile App for Your Business:"
      // Mobile apps make it easier to stay in touch with customers.
      // Mobile apps may also help strengthen your brand.
      // Customers can access your business 24/7 through mobile apps.
      // Mobile apps give customers insightful information.
      // Mobile apps are excellent marketing tools."
    },
    {
      Question: "How do you ensure the security of my mobile app?",
      Answer: "We take security very seriously and use a variety of techniques to protect your app and its data. This includes encryption, secure data storage, and regular security testing. We also stay up-to-date with the latest security threats and vulnerabilities to ensure your app is always protected."
    },
  ]
  return (
    <Layout>
      <div className="block-chain">
        <BannerComponent
          Heading="Mobile App Development Services"
          mainHeading="Transform Your Business with Innovative"
          Span="Mobile App Development"
          Extras="Solutions"
          Image={Image1}
        />
        <div className="solution">
          <div className="width75">
            <h2>Mobile App Development Solutions</h2>
            <p>The Key to Your Business Success</p>
            <Row gutter={[40, 40]}>
              {SolutionData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <SolutionsCards
                      Image={data.Image}
                      Title={data.Title}
                      Details={data.Details}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="features">
          <div className="width75">
            <h2>Our Development Features</h2>
            <p>Elevate Your Business with ICO Development Solutions</p>
            <Row gutter={[40, 40]}>
              {FeaturesData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <FeaturesCards
                      Image={data.Image}
                      Title={data.Title}
                      Link1={data.Link1}
                      Details={data.Details}
                      Link2={data.Link2}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="development-process">
          <div className="width75">
            <h2>Roadmap</h2>
            <p>Our Mobile App Development Process</p>
            <TimeLine Data={TimeLineData} />

          </div>
        </div>
        <div className="choose-us">
          <div className="bg-left">
          <div className="width75">
            <h2>Why Choose Us?</h2>
            <p>We did great in the past few years</p>
            <div className="why-us">
              <div className="border-right-bottom">
                <h3>65+</h3>
                <p>Projects Completed</p>
              </div>
              <div className="border-left-bottom">
                <h3>250+</h3>
                <p>Fullstack Developers</p>
              </div>
            </div>
            <div className="why-us">
              <div className="border-right-bottom">
                <h3>40+</h3>
                <p>Glowing Reviews</p>
              </div>
              <div className="border-left-bottom">
                <h3>70+</h3>
                <p>Happy Customers</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="width75">
            <h2>Testimonials</h2>
            <p>Reviews from Satisfied Customers</p>
            <Row gutter={[40, 40]}>
              {ReviewsData.map((data) => {
                return (
                  <Col xs={24} md={12} lg={8}>
                    <TestimonialCards
                      Image={data.Image}
                      Name={data.Name}
                      Review={data.Review}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="faq">
          <div className="width75">
            <h2>FAQ'S</h2>
            {
              FaqData.map((data, i) => {
                return (
                  <FAQ key={i} Question={data.Question} Answer={data.Answer} />

                )
              })
            }
          </div>
        </div>
        <div className="contact-us">
          <div className="width85">
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MobileApp;
