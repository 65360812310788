import { Col, Row } from "antd";
import React from "react";
import DesignCard from "../Cards/DesignCard";
import videoMarketing from "../../assets/video-marketing.jpg";
import onlineCourse from "../../assets/online-course.jpg";
import invest from "../../assets/invest.jpg";

function Design() {
  return (
    <div className="parent_container">
      <div
        className="appDevelopment_container"
        style={{ marginBottom: "100px" }}
      >
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <DesignCard
              text="Hello World"
              img={onlineCourse}
              para="A 'Hello, World!' program is generally a computer program that ignores any input and outputs or displays a message similar to 'Hello, World!'..."
            />
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <DesignCard
              text="Linux"
              img={invest}
              para="Linux.com is an official and fundamental resource that provides open source information about Linux. Here you can find the latest news..."
            />
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <DesignCard
              text="Planet Kernel"
              img={videoMarketing}
              para="This is another kernel focussed website where you will get all the latest updates on the kernel. You will also get the summit and events..."
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Design;
