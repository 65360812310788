import React from "react";
import { BiChevronRight } from "react-icons/bi";

function AboutTextCard({ text , para }) {
  return (
    <div className="AboutTextCard">
      <h1>{text}</h1>
      <p style={{ width: "90%" }}>
       {para}
      </p>
      {/* <div className="icon_div">
        <a>READ MORE</a>
        <BiChevronRight className="icon" />
      </div> */}
    </div>
  );
}

export default AboutTextCard;
