import Stars from "../../assets/Stars.png";

const TestimonialCards = ({Image, Name, Review}) => {
    return(
        <div className="testimonial-card">
             <img src={Image} />
            <h3>{Name}</h3>
            <div className='stars-image'>
                <img src={Stars} />
            </div>
            <p>{Review}</p>

        </div>
    )
}

export default TestimonialCards;