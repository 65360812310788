import Layout from "../layout/Layout";
import BannerComponent from "../components/BannerComponent";
import { Row, Col, Button } from "antd";
import SolutionsCards from "../components/Cards/SolutionsCards";
import FeaturesCards from "../components/Cards/FeaturesCards";
import WhyShould from "../components/Cards/WhyShould";
import TestimonialCards from "../components/Cards/TestimonialCards";
import FAQ from "../components/FAQ";
import Form from "../components/View/Form";
import Image1 from "../assets/Data-Analytics-Consulting-Services-1536x1148.jpg";
import Image2 from "../assets/Data-Strategy.png";
import Image3 from "../assets/Data-Visualization-and-Dashboarding.png";
import Image4 from "../assets/Predictive-Analytics.png";
import Image5 from "../assets/Data-Quality-Management.png";
import Image6 from "../assets/Business-Intelligence-Consulting.png";
import Image7 from "../assets/Data-Engineering-and-Integration.png";
import Image8 from "../assets/Unlocking-Knowledge.png";
import Image9 from "../assets/Better-Decision-Making.png";
import Image10 from "../assets/Improved-Operational-Efficiency.png";
import Image11 from "../assets/Scalability-and-Flexibility.png";
import Image12 from "../assets/Emma-Johnson.jpeg";
import Image13 from "../assets/Ananya-Desai.jpeg";
import Image14 from "../assets/Benjamin-Wilson.jpeg";
import Image15 from "../assets/Rishi-Mehta.jpeg";
import Image16 from "../assets/Jihyun-Lee.jpeg";
import Image17 from "../assets/Michael-Johnson.jpeg";

const DataAnalytics = () => {
    const SolutionData = [
        {
            Image: Image2,
            Title: "Data Strategy Consulting",
            Details:
                "We assist businesses in developing a comprehensive data strategy in accordance with their objectives, business processes, and data sources. Our data strategy consulting services include evaluating the current data infrastructure, identifying data gaps and opportunities, defining key performance indicators (KPIs), and devising a road map to attain business goals.",
        },
        {
            Image: Image3,
            Title: "Data Visualization and Dashboarding",
            Details:
                "Our data analytics consulting team specialises in the development of interactive dashboards and data visualisations that provide insights and facilitate sound decision-making. We use sophisticated data visualisation tools and techniques to convert complex data sets into actionable insights and assist businesses in monitoring their KPIs.",
        },
        {
            Image: Image4,
            Title: "Predictive Analytics",
            Details:
                "Our predictive analytics consulting services assist organisations in utilising statistical models, machine learning, and data mining techniques to forecast future trends, identify patterns, and optimise business processes. We develop individualised predictive models that enable businesses to make data-driven decisions, reduce risks, and enhance outcomes.",
        },
        {
            Image: Image5,
            Title: "Data Quality Management",
            Details:
                "We assist businesses in enhancing their data quality by instituting data quality management practices that guarantee data precision, completeness, consistency, and validity. Our data quality consulting services include data profiling, data purification, data enrichment, and data governance.",
        },
        {
            Image: Image6,
            Title: "Business Intelligence Consulting",
            Details:
                "Our business intelligence consulting services assist organisations in maximising the value of their data assets by fostering a data-driven culture and instituting BI solutions that facilitate speedier and more informed decision-making. We collaborate with businesses to identify key metrics, construct data models, and develop BI dashboards tailored to their particular requirements.",
        },
        {
            Image: Image7,
            Title: "Data Engineering and Integration",
            Details:
                "We offer consulting services for data engineering and integration that assist businesses in managing their data pipelines, integrating data from multiple sources, and preparing data for analysis. Our data engineering team is proficient in data warehousing, ETL, cloud data services, and the design of data architecture.",
        },
    ]
    const FeaturesData = [
        {
            Image: Image8,
            Title: "Unlocking Knowledge",
            Link1: "",
            Details:
                "Data Analytics Consulting assists in the discovery of insights buried inside massive volumes of data. Consultants can assist firms in discovering patterns, trends, and connections that would be difficult or impossible to find otherwise by utilising advanced analytical techniques and technologies.",
            Link2: "",
        },
        {
            Image: Image9,
            Title: "Better Decision-Making",
            Link1: "",
            Details:
                "With access to data analytics consulting insights, firms may make more informed judgements. Decisions based on data are frequently more accurate, objective, and timely than those based on intuition or insufficient information. Increased profitability, efficiency, and competitiveness can result from this.",
            Link2: "",
        },
        {
            Image: Image10,
            Title: "Improved Operational Efficiency",
            Link1: "",
            Details:
                "Data Analytics Consulting may assist firms in streamlining processes and identifying opportunities for improvement. Consultants can examine processes, systems, and workflows to discover bottlenecks, inefficiencies, and opportunities for automation. Businesses may cut costs, enhance quality, and raise customer happiness by optimising processes.",
            Link2: "",
        },
        {
            Image: Image11,
            Title: "Scalability and Flexibility",
            Link1: "",
            Details:
                "Data Analytics Consulting can give scalable and adaptable solutions to firms to match their changing demands. Consultants may assist firms in selecting the appropriate tools, technologies, and platforms for managing data and analytics operations, as well as ensuring that they interface seamlessly with current systems. Businesses may stay ahead of the competition by quickly reacting to changing market conditions.",
            Link2: "",
        },
    ]
    const WhyShouldData = [
        {
            Numbering: "01",
            Heading: "Organized and Structured Approach",
            Text: "SDLC provides a systematic and structured approach to the development of data analytics solutions. This means that your data analytics consulting service will have a well-defined process for gathering requirements, designing the solution, testing, deploying, and maintaining it. By following a well-established process, your team can provide high-quality solutions that fulfil the requirements of your clients."
        },
        {
            Numbering: "02",
            Heading: "Improved Collaboration and Communication",
            Text: "SDLC also emphasizes the importance of collaboration and communication throughout the development process. This means that your team will have regular meetings and discussions to ensure that everyone is aligned with the project goals, timelines, and requirements. By acquiring a collaborative and communicative culture, your team can work together more efficiently and effectively."
        },
        {
            Numbering: "03",
            Heading: "Increased Efficiency and Productivity",
            Text: "Finally, SDLC can help your team achieve greater efficiency and productivity. By following a standardized process, your team can avoid common pitfalls and errors that can delay or derail projects. Additionally, SDLC can help your team identify opportunities for automation, standardization, and optimization, which can further increase efficiency and productivity."
        },
    ]

    const ReviewsData = [
        {
            Image: Image12,
            Name: "Emma Johnson",
            Review:
                "I had a fantastic experience working with SDLC corp for my data analytics consulting. They provided top-notch business intelligence consulting that helped me make data-driven decisions for my company. Their data analysis services were comprehensive and provided valuable insights that helped me identify new growth opportunities. Their team was knowledgeable, professional, and efficient.",
        },
        {
            Image: Image13,
            Name: "Ananya Desai",
            Review:
                "SDLC corp, the big data analytics consulting company, helped me analyse massive volumes of data and draw conclusions from it, which would have been impossible to handle alone. They provided data mining services and data warehousing consulting that helped me organize and extract useful insights from my data. Their predictive analytics consulting also helped me anticipate future trends and make proactive decisions.",
        },
        {
            Image: Image14,
            Name: "Benjamin Wilson",
            Review:
                "I recently hired SDLC to help me present my data in a way that is easier to understand. They provided excellent services and transformed my data into meaningful and informative visualizations. Their expertise as an analytics solutions provider was apparent, and they provided valuable advice on how to improve my data architecture. I highly recommend them!",
        },
        {
            Image: Image15,
            Name: "Rishi Mehta",
            Review:
                "I cannot speak highly enough about SDLC’s data-driven consulting strategy. They helped me identify and prioritize the most important data points for my business, and their data governance consulting ensured that my data was secure and accurate. Their expertise in data integration consulting was also incredibly helpful in streamlining my processes and making my data more efficient.",
        },
        {
            Image: Image16,
            Name: "Jihyun Lee",
            Review:
                "I got connected with this machine-learning consulting firm called SDLC corp to help me leverage the latest technologies in my business. Their artificial intelligence consulting was top-notch and helped me automate several processes that were previously manual. They also provided data quality consulting to ensure that my data was accurate and reliable. Overall, their analytics solutions provider was exceptional, and I would highly recommend them.",
        },
        {
            Image: Image17,
            Name: "Michael Johnson",
            Review:
                "I recently hired SDLC’s data architecture consulting service to improve my data infrastructure and prepare for future growth. They provided comprehensive data integration consulting that helped me consolidate my data and make it more accessible. Their expertise in predictive analytics consulting also helped me anticipate potential issues and proactively address them. Their team was professional and knowledgeable, and I would recommend them without hesitation.",
        },
    ]

    const FaqData = [
        {
            Question: "What is data analytics consulting?",
            Answer: "Data analytics consulting is a service that helps organisations extract valuable insights from their data by using various analytical tools and techniques. A data analytics consultant can help businesses identify patterns, trends, and relationships within their data that can result in improved outcomes and better decision-making."
        },
        {
            Question: "How can data analytics consulting benefit my business?",
            Answer: "Data analytics consulting can benefit your business in several ways. By analysing your data, a consultant can help you identify opportunities for growth, optimise your operations, and make data-driven decisions. This can lead to increased revenue, improved customer satisfaction, and a competitive edge in your industry."
        },
        {
            Question: "What kind of data can be analysed by a data analytics consultant?",
            Answer: "A data analytics consultant can analyse various types of data, including customer data, sales data, financial data, operational data, and more. They can work with structured data (such as databases and spreadsheets) as well as unstructured data (such as social media posts and customer reviews)."
        },
        {
            Question: "How do I choose the right data analytics consultant for my business?",
            Answer: "When choosing a data analytics consultant, it’s important to look for someone with relevant experience in your industry and a track record of delivering results. You should also consider their communication skills and their ability to work collaboratively with your team. It’s a good idea to schedule a consultation to discuss your needs and goals before committing to a consultant."
        },
    ]
    return (
        <Layout>
            <div className="block-chain">
                <BannerComponent
                    Heading="Digital Transformation Consulting Services"
                    mainHeading="A Trusted Advisor for Your"
                    Span="Digital Journey"
                    Extras=""
                    Image={Image1}
                />
                <div className="solution">
                    <div className="width75">
                        <h2>Our Digital Transformation Consulting Solutions</h2>
                        <p>Transform Your Business for the Digital Age with Our Consulting Solutions</p>
                        <Row gutter={[40, 40]}>
                            {SolutionData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <SolutionsCards
                                            Image={data.Image}
                                            Title={data.Title}
                                            Details={data.Details}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="features">
                    <div className="width75">
                        <h2>Benefits of Digital Transformation Consulting Services</h2>
                        <p>
                            Digital transformation consulting services can help you achieve the following advantages
                        </p>
                        <Row gutter={[40, 40]}>
                            {FeaturesData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <FeaturesCards
                                            Image={data.Image}
                                            Title={data.Title}
                                            Link1={data.Link1}
                                            Details={data.Details}
                                            Link2={data.Link2}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="leading-choice">
                    <div className="width75">
                        <h2>Why Choose SDLC for Digital Transformation Consulting Services?</h2>
                        <p>Accelerate your digital transformation journey with expert consulting services</p>
                        <Row gutter={[40, 40]}>
                            {WhyShouldData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <WhyShould
                                            Numbering={data.Numbering}
                                            Heading={data.Heading}
                                            Text={data.Text}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="talk-expert">
                    {/* <div className="width75"> */}
                    <h2>Talk to Our Digital Transformation Consulting Expert</h2>
                    <p>Take the first step towards an innovative future and reach out to us for expert guidance on Digital Transformation</p>
                    <Button>Schedule a Free Consultation Call</Button>
                    {/* </div> */}
                </div>
                <div className="testimonial">
                    <div className="width75">
                        <h2>Testimonials</h2>
                        <p>Reviews from Satisfied Customers</p>
                        <Row gutter={[40, 40]}>
                            {ReviewsData.map((data) => {
                                return (
                                    <Col xs={24} md={12} lg={8}>
                                        <TestimonialCards
                                            Image={data.Image}
                                            Name={data.Name}
                                            Review={data.Review}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="faq">
                    <div className="width75">
                        <h2>FAQ'S</h2>
                        {
                            FaqData.map((data, i) => {
                                return (
                                    <FAQ key={i} Question={data.Question} Answer={data.Answer} />

                                )
                            })
                        }
                    </div>
                </div>
                <div className="contact-us">
                    <div className="width85">
                        <Form />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DataAnalytics;