import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";

function LoadMore() {
  const history = useHistory();
  const location = useLocation()
  return (
    <div
      onClick={() => history.push("/portfolio")}
      className={`LoadMore ${location.pathname === '/blockchain' &&'blockchainStyle'}`}
      style={{ marginBottom: -"20px" }}
    >
      <h1>LOAD MORE</h1>
      <BiChevronDown className="icon" />
    </div>
  );
}

export default LoadMore;
