import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { BsArrowRight, BsFillArrowRightCircleFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../assets/blueLogo.png";
import { BsFillEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs'

const HeaderDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const Blockchain = location.pathname === "/blockchain";

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GiHamburgerMenu
        size={25}
        onClick={showDrawer}
        style={{ cursor: "pointer" }}
        color={Blockchain ? "var(--textColor)" : "var(--text)"}
      />
      <Drawer
        // title="DECOTECHS"
        placement="right"
        open={open}
        onClose={onClose}
        closeIcon={false}
        width={400}
        className="HeaderDrawer"
      >
        <div className="headerDrawer_container">
          <div className="headerDrawer_logo">
            <div>
              <img
                src={Logo}
                alt="LOGO"
                width={150}
                onClick={() => history.push("/")}
              />
              <BsArrowRight color="var(--text)" onClick={onClose} />
            </div>
            <p>
              Organize your ideas and future plans with our smart business
              planner
            </p>
            <div className="line"></div>
          </div>
          <div className="headerDrawer_links">
            <p onClick={() => history.push("/about")}>About</p>
            <p onClick={() => history.push("/our-services")}>Our Services</p>
            <p onClick={() => history.push("/clients")}>Our Clients</p>
            {/* <p onClick={() => history.push("/bloggrid")}>Blog Grids</p> */}
            <p onClick={() => history.push("/contactus")}>Contact Us</p>
            <p onClick={() => history.push("/our-portfolio")}>Portfolio</p>
            <p onClick={() => history.push("/testimonial")}>Testimonial</p>
            <p onClick={() => history.push("/case-studies")}>Case Studies</p>

            {/* <p onClick={() => history.push("/team")}>Our Team</p> */}
            <p onClick={() => history.push("/blockchain")}>Blockchain</p>
          </div>
          <div className="contacts">
            <div className="line"></div>
            <p><BsFillEnvelopeFill style={{ color: "var(--primary)" }} /> info@decotechs.com</p>
            {/* <p><BsFillTelephoneFill style={{color:"var(--primary)"}}/> 0341 2636264</p> */}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default HeaderDrawer;
